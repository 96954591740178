import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBRow, MDBCol, MDBIcon, MDBBtn, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import firebase from "../../firebase"

import "react-datepicker/dist/react-datepicker.css";
import "./Order.css";
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';

import MeiryoRegular from "../../fonts/MeiryoRegular";
import MeiryoBold from "../../fonts/MeiryoBold";

import autoTable from "jspdf-autotable";

const nl2br = require('react-nl2br');

class OrderKaigawa extends Component {
  constructor(props) {
    super(props);
    this.selectorRef = React.createRef(null);

    this.state = {
      data: {},
      noukiSDate: '',
      noukiEDate: '',
      okurijyoNo: '',
      modal: false,
      loadingPDF: false,
      pdfModal: false,
      dataPDF: {
        deliveryAddress: {}
      },
      fileNamePDF: ''
    }
  }


  getCarts = async () => {
    let collection;
    if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.maker) {
      // manufacturer
      collection = this.props.db.collection('items_stock').doc(this.props.userOrg.id).collection('buyers').doc(this.props.org.id).collection('items')
    } else if(this.props.org && this.props.org.maker && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/Purchase mode
      collection = this.props.db.collection('items_stock').doc(this.props.org.id).collection('buyers').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.buyer) {
      // purchasing user
      collection = this.props.db.collection('items_sell').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.buyer && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/sales mode
      collection = this.props.db.collection('items_sell').doc(this.props.org.id).collection('items')
    }

    if(this.props.org && collection){
      let o = await this.props.db.collection('orders').doc(this.props.id).get();
      let data = o.data();


      // const data = doc.data();
      data.id = o.id;

      if(data.fromOrganization != this.props.userOrg.id ||
          data.toOrganization != this.props.org.id
      ){
        return;
      }

      let noukiSDate = '';
      let noukiEDate = '';
      let okurijyoNo = '';

      if(data.nouki){
        noukiSDate = data.nouki.slice(-1)[0].sdate ? data.nouki.slice(-1)[0].sdate.toDate() : '';
        noukiEDate = data.nouki.slice(-1)[0].edate ? data.nouki.slice(-1)[0].edate.toDate() : '';
      }
      if(data.okurijyoNo){
        okurijyoNo = data.okurijyoNo.slice(-1)[0].value;
      }

      let cart = data.cart;
      if (!data.newApply && !data.newReject) {
        delete cart['deletedItem'];
        await Promise.all(cart.items.map(async (item) => {
          let itemSell = await collection.doc(item.id).get();
          let itemSellData = itemSell.data();

          delete item['deletedItem'];
          delete item['deletedText'];

          if (!itemSellData) {
            cart.deletedItem = true;
            item.deletedItem = true;
            item.deletedText = '販売終了';
          } else if (itemSellData && !itemSellData['price'] ||  itemSellData && itemSellData['price'] && itemSellData['price'][0] && !itemSellData['price'][0]['price']) {
            cart.deletedItem = true;
            item.deletedItem = true;
            item.deletedText = '要見積';
          } else if (itemSellData['price'] && itemSellData['price'][0] && !itemSellData['price'][0]['expiration_date']) {
            cart.deletedItem = true;
            item.deletedItem = true;
            item.deletedText = '要見積';
          } else if (itemSellData && itemSellData['price'] && itemSellData['price'][0] && itemSellData['price'][0]['expiration_date']) {
            let expiration_date = itemSellData['price'][0]['expiration_date'];
            let expDate = expiration_date.toDate();

            if ((expDate.getTime() - this.props.dateNow) < 0) {
              cart.deletedItem = true;
              item.deletedItem = true;
              item.deletedText = '要見積';
            } else {
              item.price = itemSellData['price'][0]['price'];
            }
          } else {
            item.price = itemSellData['price'][0]['price'];
          }
          // if (itemSellData && itemSellData['price']) {
          //   item.price = itemSellData['price'][0]['price'];
          // }
        }));
      }

      this.setState({
        data: data,
        noukiSDate: noukiSDate,
        noukiEDate: noukiEDate,
        okurijyoNo: okurijyoNo,
      }, () => {
        let wrapper1 = document.getElementById('wrapper1');
        let div1 = document.getElementById('div1');
        let wrapper2 = document.getElementsByClassName('table-responsive')[0];
        let div2 = document.getElementById('div2');

        if (wrapper1 && div1 && wrapper2 && div2) {
          div1.style.width = div2.offsetWidth+'px';

          wrapper1.onscroll = function() {
            wrapper2.scrollLeft = wrapper1.scrollLeft;
          };
          wrapper2.onscroll = function() {
            wrapper1.scrollLeft = wrapper2.scrollLeft;
          };
        }
      })
      this.visitedOrder();
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.org !== prevProps.org) {
      this.getCarts();
    }
  }

  componentDidMount() {
    this.getCarts();

    // console.log('this.props', this.props)
  }



  visitedOrder = (e) => {
    let esId = this.state.data.id;

    let ordersRef = this.props.db.collection('orders').doc(esId);

    let ordersRefSet = {
      visited: {[this.props.user.id]: true},
    }

    // if (this.state.data.readBuyer === false) {
      let data = this.state.data;
      let cart = data.cart;

      let withComment = false;

      for (let i=0; i<cart.items.length; i++) {
        if (cart.items[i].readComment === false) {
          cart.items[i].readComment = true;
        }

        if (cart.items[i].okurijyoComment || cart.items[i].noukiComment) {
          withComment = true;
        }
      }

      ordersRefSet.cart = cart;

      if (withComment === true) {
        ordersRefSet.readComment = true;
      }
      // this.props.readOrder(1);

      // console.log('visit', ordersRefSet)
    // }

    ordersRef.set(ordersRefSet, { merge: true });

    if(this.props.user.authorizer){
      ordersRef.set({
        authorizer_visited: true,
      }, { merge: true });
    }
  }

  applyFile = (e) => {
    let esId = this.state.data.id;
    this.setState({
      applyModal: true,
      applyId: esId,
    })
  }
  rejectFile = (e) => {
    let esId = this.state.data.id;
    this.setState({
      rejectModal: true,
      rejectId: esId,
    })
  }
  applyClose = async (e) => {
    this.setState({
      applyModal: false,
      applyId: null,
    });

    let esId = e.currentTarget.dataset.id;
    let collection;
    if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.maker) {
      // manufacturer
      collection = this.props.db.collection('items_stock').doc(this.props.userOrg.id).collection('buyers').doc(this.props.org.id).collection('items')
    } else if(this.props.org && this.props.org.maker && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/Purchase mode
      collection = this.props.db.collection('items_stock').doc(this.props.org.id).collection('buyers').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.buyer) {
      // purchasing user
      collection = this.props.db.collection('items_sell').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.buyer && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/sales mode
      collection = this.props.db.collection('items_sell').doc(this.props.org.id).collection('items')
    }

    if(esId && collection){
      let ordersRef = this.props.db.collection('orders').doc(esId);

      let o = await ordersRef.get();
      let data = o.data();
      let cart = data.cart || {};

      await Promise.all(cart.items.map(async (item) => {
        let itemSell = await collection.doc(item.id).get();
        let itemSellData = itemSell.data();
        if (itemSellData && itemSellData['price']) {
          item.price = itemSellData['price'][0]['price'];
        }
      }));


      let username = this.props.user.last_name + ' '+ this.props.user.first_name
      await ordersRef.set({
        apply: {[this.props.user.id]: true},
        applied: true,
        newApply: true,
        cart,
        applyUserName: username,
        orderDate: firebase.firestore.Timestamp.fromDate(new Date()),
        latestUpdate: firebase.firestore.Timestamp.fromDate(new Date()),
        sortDate: firebase.firestore.Timestamp.fromDate(new Date()),
      }, { merge: true });

      let cartMainRef = this.props.db.collection('carts').doc(data.fromUser);
      cartMainRef.set({
        dummy: 'test'
      }, {merge: true})

      let cartRef = cartMainRef.collection('carts').doc(data.toOrganization);

      let deliveryAddress = {}
      if (cart.orderBasyo === '前回の納品先' || cart.orderBasyo === 'アドレス帳から選択する') {
        deliveryAddress = cart.deliveryAddress;
      }

      if (cart.orderBasyo === '納品先を直接入力する') {
        deliveryAddress = {
          propertyName: cart.orderPropertyName,
          postCode: cart.orderZipcode,
          address1: cart.orderAddr1,
          address2: cart.orderAddr2,
          officeName: cart.orderJigyosyo,
          personInCharge: cart.orderTanto,
          phoneNumber: cart.orderPhone,
          files1: cart.orderFiles1,
          files2: cart.orderFiles2
        }
      }

      if (cart.orderBasyo === '販売側からお助けカートで指定された納品先' &&
          Object.keys(cart.otasukeDeliveryAddress || {}).length > 0 &&
          cart.otasukeDeliveryAddress.postCode &&
          cart.otasukeDeliveryAddress.address1 &&
          cart.otasukeDeliveryAddress.address2 &&
          cart.otasukeDeliveryAddress.officeName &&
          cart.otasukeDeliveryAddress.personInCharge &&
          cart.otasukeDeliveryAddress.phoneNumber
      ) {
        deliveryAddress = cart.otasukeDeliveryAddress
      }

      let setWithMerge = cartRef.set({
        [data.cartNo]: {},
      }, { merge: true });

      let cartInfoRef = this.props.db.collection('carts_info').doc(data.fromUser)
          .collection('carts_info').doc(data.toOrganization);

      if (Object.keys(deliveryAddress).length > 0) {
        cartInfoRef.set({
          lastDeliveryAddress: deliveryAddress,
          update: firebase.firestore.Timestamp.fromDate(new Date())
        }, {merge: true})
      }

      this.getCarts();

      // ordersRef
      // .get()
      // .then((doc) => {
      //   if(doc.exists) {
      //     let data = doc.data();
      //
      //     let cartMainRef = this.props.db.collection('carts').doc(data.fromUser);
      //     let cartRef = cartMainRef.collection('carts').doc(data.toOrganization);
      //
      //     cartMainRef.set({
      //       dummy: 'test'
      //     }, {merge: true})
      //
      //     console.log('data',data)
      //
      //     let cart = data.cart || {};
      //
      //     let deliveryAddress = {}
      //     if (cart.orderBasyo === '前回の納品先' || cart.orderBasyo === 'アドレス帳から選択する') {
      //       deliveryAddress = cart.deliveryAddress;
      //     }
      //
      //     if (cart.orderBasyo === '納品先を直接入力する') {
      //       deliveryAddress = {
      //         propertyName: cart.orderPropertyName,
      //         postCode: cart.orderZipcode,
      //         address1: cart.orderAddr1,
      //         address2: cart.orderAddr2,
      //         officeName: cart.orderJigyosyo,
      //         personInCharge: cart.orderTanto,
      //         phoneNumber: cart.orderPhone
      //       }
      //     }
      //
      //     if (cart.orderBasyo === '販売側からお助けカートで指定された納品先' &&
      //         Object.keys(cart.otasukeDeliveryAddress || {}).length > 0 &&
      //         cart.otasukeDeliveryAddress.postCode &&
      //         cart.otasukeDeliveryAddress.address1 &&
      //         cart.otasukeDeliveryAddress.address2 &&
      //         cart.otasukeDeliveryAddress.officeName &&
      //         cart.otasukeDeliveryAddress.personInCharge &&
      //         cart.otasukeDeliveryAddress.phoneNumber
      //     ) {
      //       deliveryAddress = cart.otasukeDeliveryAddress
      //     }
      //
      //     let setWithMerge = cartRef.set({
      //       [data.cartNo]: {},
      //     }, { merge: true });
      //
      //     let cartInfoRef = this.props.db.collection('carts_info').doc(data.fromUser)
      //         .collection('carts_info').doc(data.toOrganization);
      //
      //     if (Object.keys(deliveryAddress).length > 0) {
      //       cartInfoRef.set({
      //         lastDeliveryAddress: deliveryAddress,
      //         update: firebase.firestore.Timestamp.fromDate(new Date())
      //       }, {merge: true})
      //     }
      //
      //     this.getCarts();
      //
      //   }
      // })


    }


  }
  rejectClose = async (e) => {
    this.setState({
      rejectModal: false,
      rejectId: null,
    });

    let esId = e.currentTarget.dataset.id;
    let collection;
    if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.maker) {
      // manufacturer
      collection = this.props.db.collection('items_stock').doc(this.props.userOrg.id).collection('buyers').doc(this.props.org.id).collection('items')
    } else if(this.props.org && this.props.org.maker && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/Purchase mode
      collection = this.props.db.collection('items_stock').doc(this.props.org.id).collection('buyers').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.buyer) {
      // purchasing user
      collection = this.props.db.collection('items_sell').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.buyer && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/sales mode
      collection = this.props.db.collection('items_sell').doc(this.props.org.id).collection('items')
    }

    if(esId && collection){
      let ordersRef = this.props.db.collection('orders').doc(esId);
      let o = await ordersRef.get();
      let data = o.data();

      let cart = data.cart || {};

      await Promise.all(cart.items.map(async (item) => {
        let itemSell = await collection.doc(item.id).get();
        let itemSellData = itemSell.data();
        if (itemSellData && itemSellData['price']) {
          item.price = itemSellData['price'][0]['price'];
        }
      }));

      let username = this.props.user.last_name + ' '+ this.props.user.first_name
      await ordersRef.set({
        reject: {[this.props.user.id]: true},
        rejectUserName: username,
        newReject: true,
        cart,
        rejectDate: firebase.firestore.Timestamp.fromDate(new Date()),
        latestUpdate: firebase.firestore.Timestamp.fromDate(new Date()),
        sortDate: firebase.firestore.Timestamp.fromDate(new Date()),
      }, { merge: true });

      let cartMainRef = this.props.db.collection('carts').doc(data.fromUser);
      cartMainRef.set({
        dummy: 'test'
      }, {merge: true})

      let cartRef = cartMainRef.collection('carts').doc(data.toOrganization);
      let setWithMerge = cartRef.set({
        [data.cartNo]: {
          requested: false,
          rejected: true,
        },
      }, { merge: true });

      this.getCarts();

      // ordersRef
      // .get()
      // .then((doc) => {
      //   if(doc.exists) {
      //     let data = doc.data();
      //
      //     let cartMainRef = this.props.db.collection('carts').doc(data.fromUser);
      //     let cartRef = cartMainRef.collection('carts').doc(data.toOrganization);
      //
      //     cartMainRef.set({
      //       dummy: 'test'
      //     }, {merge: true})
      //
      //     let setWithMerge = cartRef.set({
      //       [data.cartNo]: {
      //         requested: false,
      //         rejected: true,
      //       },
      //     }, { merge: true });
      //
      //     this.getCarts();
      //
      //   }
      // })

    }

  }

  pdfSave = () => {

    // const input = document.getElementById('divToPrint');
    html2canvas(this.selectorRef.current, {scale: 2})
        .then((canvas) => {

          var imgData = canvas.toDataURL('image/png');
          var imgWidth = 210;
          var pageHeight = 295;
          var pageInnerHeight = 275;
          var imgHeight = canvas.height * imgWidth / canvas.width;
          var heightLeft = imgHeight;
          var doc = new jsPDF('p', 'mm', 'a4', true);
          var position = 10; // give some top padding to first page

          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;

          while (heightLeft >= 0) {
            // position += heightLeft - imgHeight; // top padding for other pages
            position = heightLeft - imgHeight + 10
            // console.log('position', position)
            doc.addPage();
            doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }
          doc.save( this.state.fileNamePDF+'.pdf');

          // window.open(doc.output('bloburl'));
          // pdf.save("download.pdf");
        })
  }

  toggle = () => {
    this.setState({
      pdfModal: !this.state.pdfModal
    });
  }

  showPdf = async () => {
    this.setState({loadingPDF: true});

    function n(n){
      return n > 9 ? "" + n: "0" + n;
    }

    let sellerOrg = this.props.org;
    let buyerOrg = this.props.userOrg;
    let data = this.state.data;
    let cart = this.state.data.cart;
    let items = cart.items || [];
    let fileNamePDF = '';
    let deliveryAddress = {};
    let applyId = '';
    let mainInstallmentStatus = '分納手続中';

    if (data.apply) {
      applyId = Object.keys(data.apply)[0];
    }

    let orderNoukiDate = '';
    if (cart.orderNouki == '日付指定') {
      orderNoukiDate = cart.orderNoukiDate ? cart.orderNoukiDate.toDate().getFullYear() + '/'
          + n(cart.orderNoukiDate.toDate().getMonth()+1) + '/'
          + n(cart.orderNoukiDate.toDate().getDate()) + '' : '';
    }

    let toUserData = await firebase.functions().httpsCallable('getUserEmail')({uid: data.toUser})

    let date;
    if (data.orderDate) {
      date = data.orderDate
    } else {
      date = data.created;
    }
    let orderDate = date.toDate().getFullYear() + '年'
        + n(date.toDate().getMonth() + 1) + '月'
        + n(date.toDate().getDate()) + '日 '
        + n(date.toDate().getHours()) + ':'
        + n(date.toDate().getMinutes());

    let nDate = date.toDate().getFullYear() + ''
        + n(date.toDate().getMonth() + 1) + ''
        + n(date.toDate().getDate()) + ''
        + n(date.toDate().getHours()) + ''
        + n(date.toDate().getMinutes()) + ''
        + n(date.toDate().getSeconds());

    let divideDate;
    if (data.divideDate) {
      divideDate = data.divideDate.toDate().getFullYear() + ''
          + n(data.divideDate.toDate().getMonth() + 1) + ''
          + n(data.divideDate.toDate().getDate()) + ''
          + n(data.divideDate.toDate().getHours()) + ''
          + n(data.divideDate.toDate().getMinutes()) + ''
          + n(data.divideDate.toDate().getSeconds());
    }


    let no = nDate+'-'+data.id;

    //divided item-g xasax
    items = items.filter(v => v.divided !== true);

    let totalAmount = 0;
    let totalTax = 0;
    let taxes = {};
    let totals = {};
    let subDivideCount = 0;
    let subDivideConfirmCount  = 0;
    cart.items.forEach((item) => {
      if (item.subDivided) {
        subDivideCount += 1;
        if (item.divideConfirm === true) {
          subDivideConfirmCount += 1;
        }
      }
      if (!item.subDivided) {
        totalAmount += (item.price*item.count);
        totalTax += parseInt(item.count * item.price * item.tax, 10);

        if (taxes[item.tax]) {
          taxes[item.tax] += (item.count * item.price * item.tax);
          totals[item.tax] += (item.count * item.price);
        } else {
          taxes[item.tax] = (item.count * item.price * item.tax);
          totals[item.tax] = (item.count * item.price);
        }
      }
    });

    if (subDivideCount === subDivideConfirmCount) {
      mainInstallmentStatus = '分納完了済';
    } else {
      mainInstallmentStatus = '分納手続中';
    }

    switch (cart.orderBasyo) {
      case '登録住所':
        deliveryAddress = {
          postCode: this.props.userOrg.zipcode,
          address1: this.props.userOrg.address,
          address2: '',
          officeName: this.props.userOrg.display_name,
          personInCharge: this.state.data['fromUserName'],
          phoneNumber: this.props.userOrg.TEL,
          propertyName: ""
        };
        break;
      case '登録住所以外':
        deliveryAddress = {
          postCode: cart.orderZipcode,
          address1: cart.orderAddr1,
          address2: cart.orderAddr2,
          officeName: cart.orderJigyosyo,
          personInCharge: cart.orderTanto,
          phoneNumber: cart.orderPhone,
          propertyName: ""
        }
        break;
      case '前回の納品先':
        deliveryAddress = cart.deliveryAddress;
        break;
      case 'アドレス帳から選択する':
        deliveryAddress = cart.deliveryAddress;
        break;
      case '販売側からお助けカートで指定された納品先':
        deliveryAddress = cart.otasukeDeliveryAddress;
        break;
      case '納品先を直接入力する':
        deliveryAddress = {
          postCode: cart.orderZipcode,
          address1: cart.orderAddr1,
          address2: cart.orderAddr2,
          officeName: cart.orderJigyosyo,
          personInCharge: cart.orderTanto,
          phoneNumber: cart.orderPhone,
          propertyName: cart.orderPropertyName
        }
        break;

    }

    // let sAddress = deliveryAddress.propertyName + ' ' + deliveryAddress.address1 + ' ' + deliveryAddress.address2 + ' ' + deliveryAddress.officeName;

    fileNamePDF = 'ORD-'+nDate+'-'+data.id;

    let dataPDF = {
      sellerZipcode: sellerOrg.zipcode,
      sellerAddress: sellerOrg.address,
      sellerFAX: sellerOrg.FAX,
      sellerTEL: sellerOrg.TEL,
      sellerMAIL: toUserData.data,
      sellerOrganizationsCompanyName: sellerOrg.company_name,
      sellerOrganizationsName: sellerOrg.name,

      buyerZipcode: buyerOrg.zipcode,
      buyerAddress: buyerOrg.address,
      buyerFAX: buyerOrg.FAX,
      buyerTEL: buyerOrg.TEL,
      buyerOrganizationsCompanyName: buyerOrg.company_name,
      buyerOrganizationsName: buyerOrg.name,

      toUserName: data.toUserName,
      fromUserName: data.fromUserName,
      applyUserName: data.applyUserName,
      applyId: applyId,

      orderNo: cart.orderNo,
      deliveryAddress: deliveryAddress,
      orderDate: orderDate,
      orderNouki: cart.orderNouki,
      orderNoukiDay: cart.orderNoukiDay,
      orderNoukiDate: orderNoukiDate,
      orderBikou: cart.orderBikou,
      mainInstallmentStatus: mainInstallmentStatus,
      no: no,
      items: items,
      totalAmount: totalAmount,
      totalTax: totalTax,
      taxes: taxes,
      totals: totals,
      divideDate: divideDate
    }

    this.setState({dataPDF: dataPDF, pdfModal: true, fileNamePDF, loadingPDF: false}, () => {
      this.pdfGenerator(fileNamePDF, dataPDF, false);
    });
  }

  addFooters = (doc, data) => {
    const pageCount = doc.internal.getNumberOfPages()

    doc.setFont('Meiryo', 'normal')
    doc.setFontSize(8)
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text('Page ' + String(i) + '/' + String(pageCount), 15, 15);

      if (i > 1) {
        doc.text(`発注日 ${data.orderDate}\nNo.${data.no}`, 130, 15);
      }
    }
  }

  pdfGenerator = (fileName, data, download) => {
    let doc = new jsPDF('p', 'mm', 'a4', true);
    let top = 20;
    doc.setLineHeightFactor(1.15);
    doc.addFileToVFS("MeiryoRegular.ttf", MeiryoRegular);
    doc.addFileToVFS("MeiryoBold.ttf", MeiryoBold);
    doc.addFont("MeiryoRegular.ttf", "Meiryo", "normal");
    doc.addFont("MeiryoBold.ttf", "Meiryo", "bold");
    doc.setFont("Meiryo", 'normal');
    doc.setFontSize(8);
    doc.setFontSize(25);
    doc.text('発注書', 90, top);
    doc.setFontSize(8);

    if (data.divideDate) {
      doc.text(`（分納版 - ${data.divideDate})`, 195, 15, {align: 'right'});
      doc.text(`${data.mainInstallmentStatus}`, 179.5, 19);
      doc.roundedRect(178, 16.4, 17, 3.2, 0.5, 0.5);
    }

    doc.text(`〒${data.sellerZipcode}`, 15, top+20);
    doc.text(`${data.sellerAddress}`, 15, top+25);

    doc.text(`発注日 ${data.orderDate}`, 125, top+20);
    doc.text(`No.${data.no}`, 125, top+25);

    doc.setFont('Meiryo', 'bold');
    doc.text(`${data.sellerOrganizationsCompanyName} ${data.sellerOrganizationsName}`, 17, top+30);
    doc.text('御中', 110, top+30);
    doc.line(15, top+31, 110, top+31);

    doc.setFont('Meiryo', 'normal');
    doc.text(`${data.toUserName} 様`, 15, top+37);
    doc.text(`〒${data.buyerZipcode}`, 125, top+37);
    doc.text('埼玉県さいたま市浦和区', 125, top+42);
    doc.text(`${data.buyerOrganizationsCompanyName} ${data.buyerOrganizationsName}`, 125, top+50);
    doc.text(`TEL: ${data.buyerTEL}`, 125, top+55);
    doc.text(`FAX: ${data.buyerFAX}`, 170, top+55);
    doc.setFontSize(6);
    doc.text(`TEL: ${data.sellerTEL}`, 15, top+40);
    doc.text(`FAX: ${data.sellerFAX}`, 40, top+40);
    doc.text(`Mail: ${data.sellerMAIL}`, 15, top+43);
    doc.setFontSize(8);
    doc.text('以下の通り発注いたします。', 15, top+53);

    doc.text('〔本書に関するお問い合わせ〕', 125, top+63);
    let fromUserName = doc.splitTextToSize(`発注者 ${data.buyerOrganizationsCompanyName} ${data.buyerOrganizationsName} ${data.fromUserName}`, 70);
    let applyUserName = doc.splitTextToSize(`承認者 ${data.buyerOrganizationsCompanyName} ${data.buyerOrganizationsName} ${data.applyUserName || data.applyId}`, 70)
    doc.text(fromUserName, 125, top+68);
    doc.text(applyUserName, 125, top+78);

    top = top+63

    doc.text('発注番号:', 15, top);
    let text = `${data.orderNo}`.trim();
    let da = doc.splitTextToSize(text, 75, {fontSize: 8, lineHeightFactor: 1.15});
    let amountOfLines = da.length;
    doc.text(da, 40, top);
    if (amountOfLines > 1) {
      top += doc.getTextDimensions(da)['h']-2;
    } else {
      top += 1
    }
    doc.line(40, top, 115, top);

    top += 4;
    doc.text('納品場所:', 15, top);
    let text1 = `〒${data.deliveryAddress.postCode}`.trim();
    let da1 = doc.splitTextToSize(text1, 75, {fontSize: 8, lineHeightFactor: 1.15});
    let amountOfLines1 = da1.length;
    doc.text(da1, 40, top);
    if (amountOfLines1 > 1) {
      top += doc.getTextDimensions(da1)['h']-2;
    } else {
      top += 3;
    }

    let text2 = `${data.deliveryAddress.propertyName} ${data.deliveryAddress.address1} ${data.deliveryAddress.address2} ${data.deliveryAddress.officeName}`.trim();
    let da2 = doc.splitTextToSize(text2, 75, {fontSize: 8, lineHeightFactor: 1.15});
    let amountOfLines2 = da2.length;
    doc.text(da2, 40, top);
    if (amountOfLines2 > 1) {
      top += doc.getTextDimensions(da2)['h']-2;
    } else {
      top += 1
    }
    doc.line(40, top, 115, top);

    top += 4;
    doc.text('受取担当者:', 15, top);
    let text3 = `${data.deliveryAddress.personInCharge} ${data.deliveryAddress.personInCharge && '様'}`.trim();
    let da3 = doc.splitTextToSize(text3, 75, {fontSize: 8, lineHeightFactor: 1.15});
    let amountOfLines3 = da3.length;
    doc.text(da3, 40, top);
    if (amountOfLines3 > 1) {
      top += doc.getTextDimensions(da3)['h']-2;
    } else {
      top += 1
    }
    doc.line(40, top, 115, top);

    top += 4;
    doc.text('電話番号:', 15, top);
    let text4 = `${data.deliveryAddress.phoneNumber}`.trim();
    let da4 = doc.splitTextToSize(text4, 75, {fontSize: 8, lineHeightFactor: 1.15});
    let amountOfLines4 = da4.length;
    doc.text(da4, 40, top);
    if (amountOfLines4 > 1) {
      top += doc.getTextDimensions(da4)['h']-2;
    } else {
      top += 1
    }
    doc.line(40, top, 115, top);

    top += 4;
    doc.text('希望納期:', 15, top);
    let orderNouki = data['orderNouki'] === '最短' ? '最短' : data['orderNouki'] === '希望' ? `${data['orderNoukiDay']}日` : `${data['orderNoukiDate']}`
    let da5 = doc.splitTextToSize(`${orderNouki}`, 75, {fontSize: 8, lineHeightFactor: 1.15});
    let amountOfLines5 = da5.length;
    doc.text(da5, 40, top);
    if (amountOfLines5 > 1) {
      top += doc.getTextDimensions(da5)['h']-2;
    } else {
      top += 1
    }
    doc.line(40, top, 115, top);

    autoTable(doc, {
      startY: top+8,
      head: [['税抜合計金額', '消費税額', '今回発注額']],
      body: [
        [
          `${data.totalAmount.toLocaleString()}円`,
          `${parseInt((Object.values(data.taxes).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0)),10).toLocaleString()}円`,
          `${(data.totalAmount + parseInt((Object.values(data.taxes).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0)),10)).toLocaleString()}円`],
      ],
      headStyles: {
        fillColor: [189, 190, 191],
        textColor: [0],
        halign: 'center',
        font: 'Meiryo',
        fontSize: 8,
        lineWidth: .1,
        lineColor: 'black'
      },
      columnStyles: {
        0: { cellWidth: 33, halign: 'right' },
        1: { cellWidth: 34, halign: 'right' },
        2: { cellWidth: 34, halign: 'right' }
      },
      bodyStyles: {
        font: 'Meiryo',
        fontSize: 8,
        color: 0,
        lineWidth: .1,
        lineColor: 'black'
      },
      theme: 'plain'
    });

    let posY = doc.lastAutoTable.finalY;
    // console.log('posY', posY);

    autoTable(doc, {
      startY: posY+8,
      head: [['課税対象額(10%)', '消費税(10%)', '課税対象額(8%)', '消費税(8%)']],
      body: [
        [
          `${(data.totals['0.1'] || 0).toLocaleString()}円`,
          `${parseInt((data.taxes['0.1'] || 0), 10).toLocaleString()}円`,
          `${(data.totals['0.08'] || 0).toLocaleString()}円`,
          `${parseInt((data.taxes['0.08'] || 0), 10).toLocaleString()}円`
        ],
      ],
      headStyles: {
        fillColor: [189, 190, 191],
        textColor: [0],
        halign: 'center',
        font: 'Meiryo',
        fontSize: 8,
        lineWidth: .1,
        lineColor: 'black'
      },
      columnStyles: {
        0: { cellWidth: 33, halign: 'right' },
        1: { cellWidth: 34, halign: 'right' },
        2: { cellWidth: 34, halign: 'right' },
        3: { cellWidth: 34, halign: 'right' }
      },
      bodyStyles: {
        font: 'Meiryo',
        fontSize: 8,
        color: 0,
        lineWidth: .1,
        lineColor: 'black'
      },
      theme: 'plain'
    });

    posY = doc.lastAutoTable.finalY;

    autoTable(doc, {
      startY: posY+8,
      head: [['備考', 'orderBikou']],
      showHead: false,
      body: [
        ['備考', `${data['orderBikou'] ? data['orderBikou'] : ''}`],
      ],
      headStyles: {
        fillColor: [189, 190, 191],
        textColor: [0],
        halign: 'center',
        font: 'Meiryo',
        fontSize: 8,
        lineWidth: .1,
        lineColor: 'black'
      },
      columnStyles: {
        0: { cellWidth: 15, minCellHeight: 20, halign: 'center', valign: 'middle', fillColor: [189, 190, 191] },
        1: { cellWidth: 'auto', halign: 'left', valign: 'middle' },
      },
      bodyStyles: {
        font: 'Meiryo',
        fontSize: 8,
        color: 0,
        lineWidth: .1,
        lineColor: 'black'
      },
      theme: 'plain'
    });

    posY = doc.lastAutoTable.finalY;

    const substringFullWidth = (str, limit) => {
      var l = str.length;
      var c = '';
      var res = '';
      var length = 0;
      for (var i = 0; i < l; i++) {
        c = str.charCodeAt(i);
        if (0x0000 <= c && c <= 0x0019) {
          // length 0
          // do nothing
        } else if (0x0020 <= c && c <= 0x1FFF) {
          // length 1
          length = length + 1;
        } else if (0x2000 <= c && c <= 0xFF60) {
          // length 2
          length = length + 2;
        } else if (0xFF61 <= c && c <= 0xFF9F) {
          // length 1
          length = length + 1;
        } else if (0xFFA0 <= c) {
          // length 2
          length = length + 2;
        }
        if (length <= limit) {
          res += str.charAt(i);
        }
        else {
          break;
        }
      }
      return res;
    }

    let no = 0;
    let subNum = 0;
    let subId = '';
    let itemDataBody = data.items.map((item, index) => {
      if (subId !== item.id) {
        subId = item.id;
        no += 1;
        if (item.subDivided) {
          subNum = 1;
        } else {
          subNum = 0;
        }
      } else {
        if (item.subDivided) {
          subNum += 1;
        } else {
          subNum = 0;
        }
      }

      let confirmStatus = '';
      if (item.subDivided) {
        // confirmStatus = <span style={{...styles.installmentStatus, fontSize: 11, marginRight: 0}}>分納手続中</span>;
        confirmStatus = `分納手続中`;
        if (item.divideConfirm) {
          // confirmStatus = <span style={{...styles.installmentStatus, fontSize: 11, marginRight: 0}}>分納</span>
          confirmStatus = `分納`;
        }
      }

      return [
        `${no}${subNum > 0 ? `\n(${subNum})` : ''}`,
        `${item.code ? item.code : ''}${item.subDivided ? `\n${confirmStatus}` : ''}`,
        `${item.maker}\n${substringFullWidth(item.name,156)}`,
        `${item.price.toLocaleString()}\n${item.count.toLocaleString()}`,
        `${(item.count * item.price).toLocaleString()}\n${item.tax*100}%`,
        // `${item.description ? substringFullWidth(item.description,87).trim() : ''}`
          ``
      ]
    });

    autoTable(doc, {
      startY: posY+8,
      margin: {top: 20},
      rowPageBreak: 'avoid',
      head: [['No.', '商品コード', 'メーカー名\n商品名', '単価(円)\n数量', '合計金額(円)\n税率', '備考']],
      body: itemDataBody,
      styles: {
        font: 'Meiryo',
        fontSize: 8
      },
      headStyles: {
        fillColor: [189, 190, 191],
        textColor: [0],
        halign: 'center',
        font: 'Meiryo',
        fontSize: 8,
        lineWidth: .1,
        lineColor: 'black'
      },
      columnStyles: {
        0: { cellWidth: 10, halign: 'center', valign: 'middle'},
        1: { cellWidth: 20, halign: 'left', valign: 'middle'},
        2: { cellWidth: 'auto', halign: 'left', valign: 'middle'},
        3: { cellWidth: 15, halign: 'right', valign: 'middle'},
        4: { cellWidth: 25, halign: 'right', valign: 'middle'},
        5: { cellWidth: 35, halign: 'left', valign: 'middle', minCellHeight: 15}
      },
      bodyStyles: {
        font: 'Meiryo',
        fontSize: 8,
        color: 0,
        lineWidth: .1,
        lineColor: 'black'
      },
      theme: 'plain',
      didDrawCell: (data) => {
        if (data.section === 'body' && data.column.index === 1) {
          if ((data.cell.text[1] === '分納手続中' || data.cell.text[1] === '分納') || (data.cell.text[2] === '分納手続中' || data.cell.text[2] === '分納')) {
            let w = 16.6;
            if (data.cell.text[1] === '分納' || data.cell.text[2] === '分納') {
              w = 8.5;
            }
            doc.roundedRect(data.cell.x+0.5, data.cell.y+data.cell.contentHeight+(data.cell.height-data.cell.contentHeight)/2-5.3, w, 3.2, 0.5, 0.5);
          }
        }
      },
      willDrawCell: (d) => {
        if (d.section === 'body' && d.column.index === 5) {
          let desc = data.items[d.row.index]['description'] || '';
          let a =  doc.splitTextToSize(desc, 32);
          d.cell.text = a.slice(0,4);
        }
      }
    });

    this.addFooters(doc, data);

    if (download) {
      doc.save(`${fileName}.pdf`)
    } else {
      // console.log('fileName1', fileName, this.state.fileNamePDF);
      doc.setProperties({title: this.state.fileNamePDF});
      setTimeout(() => {
        document.getElementById("showPDF").data = doc.output('datauristring');
      }, 10);
    }

  }

  render() {
    if (!this.props.org) {
      return (
        <>
          取引先を選択してください。
        </>
      )
    }
    if (!this.state.data.id) {
      return (
        <>
        </>
      )
    }

    function n(n){
      return n > 9 ? "" + n: "0" + n;
    }

    console.log('kaigawa');

    let cart = [];
    let total = 0;
    let postage = 0;
    let taxes = {};
    let taxTotal = 0;

    let cartData = {
      items: [],
      orderNo: '',
      toUser: '',
      toUserName: '',
      orderNouki: '最短',
      orderNoukiDay: '',
      orderBasyo: '登録住所',
      orderZipcode: '',
      orderAddr1: '',
      orderAddr2: '',
      orderJigyosyo: '',
      orderTanto: '',
      orderPhone: '',
      orderBikou: '',
      requested: false,
    };
    if(this.state.data && this.state.data.cart && this.state.data.cart.items){
      Object.assign(cartData, this.state.data.cart);
      let items = this.state.data.cart.items;

      let no = 0;
      let subDividedNo = 1;

      items.forEach((item, index) => {
        let date = item.update.toDate().getFullYear() + '年'
              + (item.update.toDate().getMonth() + 1) + '月'
              + item.update.toDate().getDate() + '日';

              let noukiSDate = '';
              let noukiEDate = '';
              let okurijyoNo = '';
              let hassouDate = '';

              if(item.nouki){
                noukiSDate = item.nouki.slice(-1)[0].sdate
                              ? item.nouki.slice(-1)[0].sdate.toDate().getFullYear() + '/'
                               + n(item.nouki.slice(-1)[0].sdate.toDate().getMonth()+1) + '/'
                               + n(item.nouki.slice(-1)[0].sdate.toDate().getDate())
                              : '';
                noukiEDate = item.nouki.slice(-1)[0].edate
                              ? item.nouki.slice(-1)[0].edate.toDate().getFullYear() + '/'
                               + n(item.nouki.slice(-1)[0].edate.toDate().getMonth()+1) + '/'
                               + n(item.nouki.slice(-1)[0].edate.toDate().getDate())
                              : '';
              }

              let okurijyoProcess = <div style={{width: 15, height: 15, borderRadius: 10, backgroundColor: '#FF3547'}}/>;

              if(item.okurijyoNo){
                okurijyoNo = item.okurijyoNo.slice(-1)[0].value;
                hassouDate = item.okurijyoNo.slice(-1)[0].hassouDate
                              ? item.okurijyoNo.slice(-1)[0].hassouDate.toDate().getFullYear() + '/'
                               + n(item.okurijyoNo.slice(-1)[0].hassouDate.toDate().getMonth()+1) + '/'
                               + n(item.okurijyoNo.slice(-1)[0].hassouDate.toDate().getDate())
                              : '';

                if (okurijyoNo && hassouDate) {
                  okurijyoProcess = <div style={{width: 15, height: 15, borderRadius: 10, backgroundColor: '#19A22B'}}/>;
                }
              }

              let count = <div className={'text-nowrap'}>
                <div style={{textAlign: 'right'}}><span style={{color: "darkgray"}}>@</span>{item.price.toLocaleString() + '円'}</div>
                <div style={{textAlign: 'right', fontSize: 18, fontWeight: 'bold'}}>{item.count}</div>
              </div>
              let price = <p className="text-right">{(item.count * item.price).toLocaleString() + '円'}</p>;

              if (item.deletedItem) {
                count = <div style={{textAlign: 'right'}}><span style={{color: 'red'}}>{item.deletedText}</span></div>;
                price = '';
              }

              let templatedNo;
              if (item.subDivided) {
                templatedNo = n(no)+'-'+subDividedNo;
                subDividedNo += 1;
              } else {
                no += 1;
                subDividedNo = 1;
                templatedNo = n(no)
              }

              cart.push({
                // date: date,
                no: <div>
                  {templatedNo}<br/>
                  {/*{item.subDivided && <div className={'divideTag'}>分納</div>}*/}
                  {item.subDivided && item.divideConfirm === true && <div className={'divideTag'}>分納</div>}
                  {item.subDivided && item.divideConfirm === false && <div className={'dividedStatusProgress'}>分納手続中</div>}
                  {item.readComment === false && <div><MDBIcon icon="comments" className="mr-1 commentIcon" /></div>}
                  {item.readComment === true && <div><MDBIcon icon="comments" className="mr-1 commentIconGray" /></div>}
                </div>,
                code: item.code,
                name: <Link to={"/items/" + item.id}>{item.name}</Link>,
                maker: item.maker,
                count: count,
                price: price,
                description: <div style={{overflowWrap: 'anywhere'}}>{item.description}</div>,
                nouki: <>
                  {noukiSDate} 〜 {noukiEDate}
                  {item.noukiComment && <>
                    <br/>
                    <span style={{height: 20}}>
                      <MDBIcon icon="comments" className="mr-1 commentIconGray" />
                    </span>
                    <br/>
                    {item.noukiComment}
                  </>}
                </>,
                okurijyo: <>
                  {hassouDate} / {okurijyoNo}
                  {item.okurijyoComment && <>
                    <br/>
                    <span style={{height: 20}}>
                      <MDBIcon icon="comments" className="mr-1 commentIconGray" />
                    </span>
                    <br/>
                    {item.okurijyoComment}
                  </>}
                </>,
                okurijyoProcess: <div style={{display: 'flex', justifyContent: 'center', width: 60}}>{!item.divided && okurijyoProcess}</div>
              })

        if (!item.deletedItem && !item.subDivided) {
          if (!item.tax) {
            item.tax = 0.1;
          }
          if (taxes[item.tax]) {
            taxes[item.tax] += (item.count * item.price * item.tax);
          } else {
            taxes[item.tax] = (item.count * item.price * item.tax);
          }
          taxTotal += parseInt(item.count * item.price * item.tax, 10);
          total += item.count * item.price;
        }

      });
      postage = cartData.carriage ? cartData.carriage : 0;
      total += postage;
    }
    const isEnable = (cartData.toUser !== '')
    && (this.state.orderNouki !== '')
    && (this.state.orderBasyo !== '');

    const data = {
      columns: [
        {
          label: 'No.',
          field: 'no',
        },
        {
          label: '商品コード',
          field: 'code',
          sort: 'asc',
          width: 150,
          minimal: 'sm'
        },
        {
          label: '商品名',
          field: 'name',
          sort: 'asc',
          width: 150,
          minimal: 'sm'
        },
        {
          label: 'メーカー名',
          field: 'maker',
          sort: 'asc',
          width: 150,
          minimal: 'sm'
        },
        {
          label: '単価/数量',
          field: 'num',
          sort: 'asc',
          width: 270,
          minimal: 'sm'
        },
        {
          label: '金額',
          field: 'price',
          sort: 'asc',
          width: 270,
          minimal: 'sm'
        },
        {
          label: '備考',
          field: 'description',
          sort: 'asc',
          width: 150,
          minimal: 'sm'
        },
        {
          label: '納期回答',
          field: 'nouki',
          sort: 'asc',
          width: 270,
          minimal: 'sm'
        },
        {
          label: '出荷日/送り状番号',
          field: 'okurijyo',
          sort: 'asc',
          width: 270,
          minimal: 'sm'
        },
        {
          label: <div>
            <row style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><div style={{width: 15, height: 15, borderRadius: 10, backgroundColor: '#19A22B', marginRight: 5}}/>出荷済</row>
            <row style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><div style={{width: 15, height: 15, borderRadius: 10, backgroundColor: '#FF3547', marginRight: 5}}/>未出荷</row>
          </div>,
          field: 'okurijyoProcess',
          width: 200,
          minimal: 'sm'
        }
      ],
      //rows: this.state.dbItems

      rows: cart
    }

    // orderDate
    let orderDate = '';
    if(this.state.data && this.state.data['orderDate'] && this.state.data.cart && this.state.data.cart.items){
      orderDate = this.state.data['orderDate'].toDate().getFullYear() + '/'
          + n(this.state.data['orderDate'].toDate().getMonth()+1) + '/'
          + n(this.state.data['orderDate'].toDate().getDate()) + ' '
          + n(this.state.data['orderDate'].toDate().getHours()) + ':'
          + n(this.state.data['orderDate'].toDate().getMinutes());
    }else {
      orderDate = this.state.data['created'].toDate().getFullYear() + '/'
          + n(this.state.data['created'].toDate().getMonth()+1) + '/'
          + n(this.state.data['created'].toDate().getDate()) + ' '
          + n(this.state.data['created'].toDate().getHours()) + ':'
          + n(this.state.data['created'].toDate().getMinutes());
    }

    // created
    let date = '';
    if(this.state.data && this.state.data['latestUpdate'] && this.state.data.cart && this.state.data.cart.items){
      date = this.state.data['latestUpdate'].toDate().getFullYear() + '/'
           + n(this.state.data['latestUpdate'].toDate().getMonth()+1) + '/'
           + n(this.state.data['latestUpdate'].toDate().getDate()) + ' '
          + n(this.state.data['latestUpdate'].toDate().getHours()) + ':'
          + n(this.state.data['latestUpdate'].toDate().getMinutes());
    }else {
      date = this.state.data['created'].toDate().getFullYear() + '/'
          + n(this.state.data['created'].toDate().getMonth()+1) + '/'
          + n(this.state.data['created'].toDate().getDate()) + ' '
          + n(this.state.data['created'].toDate().getHours()) + ':'
          + n(this.state.data['created'].toDate().getMinutes());
    }

    let orderNoukiDate = '';
    if (this.state.data['cart'].orderNouki == '日付指定') {
      orderNoukiDate = this.state.data['cart'].orderNoukiDate ? this.state.data['cart'].orderNoukiDate.toDate().getFullYear() + '/'
          + (this.state.data['cart'].orderNoukiDate.toDate().getMonth()+1) + '/'
          + this.state.data['cart'].orderNoukiDate.toDate().getDate() + '' : '';
    }

    let rejectDate = '';
    let rejectUserName = '';
    if(this.state.data && this.state.data['rejectDate']){
      rejectDate = this.state.data['rejectDate'].toDate().getFullYear() + '/'
          + n(this.state.data['rejectDate'].toDate().getMonth()+1) + '/'
          + n(this.state.data['rejectDate'].toDate().getDate()) + ' '
          + n(this.state.data['rejectDate'].toDate().getHours()) + ':'
          + n(this.state.data['rejectDate'].toDate().getMinutes());

      rejectUserName = this.state.data['rejectUserName'];
    }

    let visited = false;
    if(this.state.data['visited'] && this.state.data['visited'][this.props.user.id]) {
      visited = true;
    }

    let actionButtons = false;
    if(this.props.user.authorizer){
      if(this.state.data['apply'] && Object.keys(this.state.data['apply']).length > 0) {
      } else if(this.state.data['reject'] && Object.keys(this.state.data['reject']).length > 0) {
      } else {
        actionButtons = true;
      }
    }

    let deliveryAddress = cartData['deliveryAddress'] || {};
    let otasukeDeliveryAddress = cartData['otasukeDeliveryAddress'] || {};

    setTimeout(() => {
      let data = this.state.data;
      let cart = data.cart;
      let items = cart.items;

      let dividedIndexes = [];
      for (let i=0; i<items.length; i++) {
        if (items[i].divided) {
          dividedIndexes.push(i);
        }
      }
      let trs = document.querySelectorAll(".itemListTable tr");
      for (let i=0; i<trs.length; i++) {
        for (let a=0; a<dividedIndexes.length; a++) {
          if (i === dividedIndexes[a]+1) {
            trs[i].classList.add('disabledTR')
          }
        }
      }
    }, 0)


    return (
      <>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <h2>発注書</h2>
          {this.state.data.applied && <MDBBtn disabled={this.state.loadingPDF} onClick={() => this.showPdf()}>
            {this.state.loadingPDF && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{marginRight: 10}}/>}
            PDF 表示
          </MDBBtn>}
        </div>

        <MDBModal isOpen={this.state.pdfModal} toggle={this.toggle} size="xl">
          <MDBModalHeader toggle={this.toggle}>PDF 表示</MDBModalHeader>
          <MDBModalBody style={{padding: 30}}>
            <div style={{display: "inline-block", marginBottom: 10}}>
              <MDBBtn
                  className="btn-sm btn-block"
                  onClick={() => this.pdfGenerator(this.state.fileNamePDF, this.state.dataPDF, true)}
              >PDF ダウンロード</MDBBtn>
            </div>
            <div style={{overflow: 'auto', position: 'relative', border: '1px solid lightgray'}}>
              <object id="showPDF" type="application/pdf" style={{width: 1080, height: '75vh'}}/>
            </div>
          </MDBModalBody>
        </MDBModal>

        <div id="wrapper1" style={{height: 20, overflow: 'auto'}}>
          <div id="div1" style={{height: 20}}>
          </div>
        </div>
        <MDBTable btn responsive className={'itemListTable'} id={"div2"}>
          <MDBTableHead color="primary-color" textWhite columns={data.columns} />
          <MDBTableBody rows={data.rows} />
        </MDBTable>

        <MDBTable className="mt-5">
          <MDBTableBody>
            <tr>
              <th>運賃</th>
              <td className="text-right">{postage.toLocaleString()}円</td>
            </tr>
            <tr>
              <th>小計</th>
              <td className="text-right">{total.toLocaleString()}円</td>
            </tr>
            <tr>
              <th>消費税額</th>
              <td className="text-right">{parseInt((Object.values(taxes).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0)),10).toLocaleString()}円</td>
            </tr>
            {Object.keys(taxes).sort((a, b) => { return b-a }).map(value => {
              return <tr>
                <th>&nbsp; 消費税{value*100}%</th>
                <td className="text-right">{parseInt((taxes[value]),10).toLocaleString()}円</td>
              </tr>
            })}
            {/*<tr>*/}
            {/*  <th>消費税10%</th>*/}
            {/*  <td className="text-right">{parseInt((total * 0.1),10).toLocaleString()}円</td>*/}
            {/*</tr>*/}
            <tr>
              <th>合計</th>
              <td className="text-right">{parseInt((total + parseInt((Object.values(taxes).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0)),10)),10).toLocaleString()}円</td>
            </tr>
          </MDBTableBody>
        </MDBTable>
        { cart.length>0 &&
        <div>

        <div className="p-3">

          <div className="form-group row mt-0">
            <label className="col-sm-2 p-2 bg-light">発注番号</label>
            <div className="col-sm-10 p-2">
              {cartData.orderNo}
            </div>

            <label className="col-sm-2 p-2 bg-light">発注日時</label>
            <div className="col-sm-10 p-2">
              {orderDate}
            </div>

            <label className="col-sm-2 p-2 bg-light">更新日時</label>
            <div className="col-sm-10 p-2">
              {date}
              {rejectDate && <span style={{marginLeft: 50, color: 'red'}}>{`却下: ${rejectDate} (${rejectUserName}) `}</span>}
            </div>

            <label className="col-sm-2 p-2 bg-light">発注者</label>
            <div className="col-sm-10 p-2">
              {this.state.data['fromUserName']}
            </div>

            <label className="col-sm-2 p-2 bg-light">納品場所</label>
            <div className="col-sm-10 p-2">
              {cartData['orderBasyo'] == '登録住所' &&
                <>
                  (登録住所)<br />
                  {this.props.userOrg.zipcode}<br />
                  {this.props.userOrg.address}<br />
                  {this.props.userOrg.display_name}<br />
                  受取担当者：{this.state.data['fromUserName']}<br />
                  電話番号：{this.props.userOrg.TEL}<br/>
                  {/*image: {cartData['files1'] && <StorageLink src={cartData['files1']} storage={this.props.storage} target="_blank">*/}
                  {/*  <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                  {/*</StorageLink>}*/}
                </>
              }
              {cartData['orderBasyo'] == '登録住所以外' &&
                <>
                  (登録住所以外)<br />
                  {cartData['orderZipcode']}<br />
                  {cartData['orderAddr1']}<br />
                  {cartData['orderAddr2']}<br />
                  {cartData['orderJigyosyo']}<br />
                  受取担当者：{cartData['orderTanto']}<br />
                  電話番号：{cartData['orderPhone']}<br/>
                  {/*image: {cartData['orderFiles1'] && <StorageLink src={cartData['orderFiles1']} storage={this.props.storage} target="_blank">*/}
                  {/*  <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                  {/*</StorageLink>}*/}
                  {/*{cartData['orderFiles2'] && <StorageLink src={cartData['orderFiles2']} storage={this.props.storage} target="_blank">*/}
                  {/*  <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                  {/*</StorageLink>}*/}
                </>
              }
              {cartData['orderBasyo'] == '前回の納品先' &&
                  <>
                    (前回の納品先)<br/>
                    {deliveryAddress.propertyName}<br />
                    {deliveryAddress.postCode}<br />
                    {deliveryAddress.address1}<br />
                    {deliveryAddress.address2}<br />
                    {deliveryAddress.officeName}<br />
                    受取担当者：{deliveryAddress.personInCharge}<br />
                    電話番号：{deliveryAddress.phoneNumber}<br/>
                    {/*image: {deliveryAddress.files1 && <StorageLink src={deliveryAddress.files1} storage={this.props.storage} target="_blank">*/}
                    {/*  <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                    {/*</StorageLink>}*/}
                    {/*{deliveryAddress.files2 && <StorageLink src={deliveryAddress.files2} storage={this.props.storage} target="_blank">*/}
                    {/*  <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                    {/*</StorageLink>}*/}
                  </>
              }
              {cartData['orderBasyo'] == 'アドレス帳から選択する' &&
                <>
                  (アドレス帳から選択する)<br/>
                  {deliveryAddress.propertyName}<br />
                  {deliveryAddress.postCode}<br />
                  {deliveryAddress.address1}<br />
                  {deliveryAddress.address2}<br />
                  {deliveryAddress.officeName}<br />
                  受取担当者：{deliveryAddress.personInCharge}<br />
                  電話番号：{deliveryAddress.phoneNumber}<br/>
                  {/*image: {deliveryAddress.files1 && <StorageLink src={deliveryAddress.files1} storage={this.props.storage} target="_blank">*/}
                  {/*  <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                  {/*</StorageLink>}*/}
                  {/*{deliveryAddress.files2 && <StorageLink src={deliveryAddress.files2} storage={this.props.storage} target="_blank">*/}
                  {/*  <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                  {/*</StorageLink>}*/}
                </>
              }
              {cartData['orderBasyo'] === '販売側からお助けカートで指定された納品先' &&
                  <>
                    (販売側からお助けカートで指定された納品先)<br/>
                    {otasukeDeliveryAddress.propertyName}<br />
                    {otasukeDeliveryAddress.postCode}<br />
                    {otasukeDeliveryAddress.address1}<br />
                    {otasukeDeliveryAddress.address2}<br />
                    {otasukeDeliveryAddress.officeName}<br />
                    受取担当者：{otasukeDeliveryAddress.personInCharge}<br />
                    電話番号：{otasukeDeliveryAddress.phoneNumber}<br/>
                    {/*image: {otasukeDeliveryAddress.files1 && <StorageLink src={otasukeDeliveryAddress.files1} storage={this.props.storage} target="_blank">*/}
                    {/*  <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                    {/*</StorageLink>}*/}
                    {/*{otasukeDeliveryAddress.files2 && <StorageLink src={otasukeDeliveryAddress.files2} storage={this.props.storage} target="_blank">*/}
                    {/*  <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                    {/*</StorageLink>}*/}
                  </>
              }
              {cartData['orderBasyo'] == '納品先を直接入力する' &&
                <>
                  (納品先を直接入力する)<br/>
                  {cartData['orderPropertyName']}<br />
                  {cartData['orderZipcode']}<br />
                  {cartData['orderAddr1']}<br />
                  {cartData['orderAddr2']}<br />
                  {cartData['orderJigyosyo']}<br />
                  受取担当者：{cartData['orderTanto']}<br />
                  電話番号：{cartData['orderPhone']}<br/>
                  {/*image: {cartData['orderFiles1'] && <StorageLink src={cartData['orderFiles1']} storage={this.props.storage} target="_blank">*/}
                  {/*  <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                  {/*</StorageLink>}*/}
                  {/*{cartData['orderFiles2'] && <StorageLink src={cartData['orderFiles2']} storage={this.props.storage} target="_blank">*/}
                  {/*  <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                  {/*</StorageLink>}*/}
                </>
              }
            </div>

            <label className="col-sm-2 p-2 bg-light">希望納期</label>
            <div className="col-sm-10 p-2">
              {cartData['orderNouki'] == '最短' ? '最短' : cartData['orderNouki'] == '希望' ?
                <>
                {cartData['orderNoukiDay']}日
                </> :
                <>
                  {orderNoukiDate}
                </>
              }
            </div>

            <label className="col-sm-2 p-2 bg-light">備考</label>
            <div className="col-sm-10 p-2">
              {nl2br(cartData['orderBikou'])}
            </div>

            {false &&
            <>
            <label className="col-sm-2 p-2 bg-light">送り状番号</label>
            <div className="col-sm-10 p-2">
              {this.state.okurijyoNo}
            </div>
            </>
            }

          </div>

        </div>


        { actionButtons &&

          <div className="text-center">

            <MDBBtn
              color=""
              // bgColor="default-color"
              text="white"
              className="btn-lg text-nowrap col-sm-5 customGreenButton"
              disabled={cartData['deletedItem']}
              onClick={this.applyFile}>承認（発注確定）確認</MDBBtn>

            <MDBBtn
              color=""
              // bgColor="warning-color-dark"
              text="white"
              className="btn-lg text-nowrap col-sm-5 customOrangeButton"
              onClick={this.rejectFile}>却下（担当者に差戻）確認</MDBBtn>
          </div>

        }

         </div>
        }

        <MDBModal isOpen={this.state.applyModal} toggle={this.applyClose}>
          <MDBModalHeader toggle={this.applyClose}
                  className="text-center default-color white-text"
                  titleClass="w-100 font-weight-bold"
          >承認(発注確定)</MDBModalHeader>
          <MDBModalBody>
          発注を承認しますか？<br />
          承認すると発注が確定し、販売会社に通知されます。<br />
          よろしいですか？
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.applyClose} color="secondary">キャンセル</MDBBtn>
            <MDBBtn onClick={this.applyClose} color="default" data-id={this.state.applyId}>承認(発注確定)する</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.rejectModal} toggle={this.rejectClose}>
          <MDBModalHeader toggle={this.rejectClose}
                  className="text-center warning-color-dark white-text"
                  titleClass="w-100 font-weight-bold"
          >却下（担当者に差戻）</MDBModalHeader>
          <MDBModalBody>
          発注を却下（担当者に差戻）しますか？<br />
          本発注書での注文はできなくなります。<br />
          よろしいですか？
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.rejectClose} color="secondary">キャンセル</MDBBtn>
            <MDBBtn onClick={this.rejectClose} color="danger" data-id={this.state.rejectId}>却下（担当者に差戻）する</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </>
    );
  }
}

export default OrderKaigawa;
