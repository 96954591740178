import React from "react";
import { BrowserRouter, Route, Link } from 'react-router-dom'
import { MDBSpinner, MDBCol, MDBRow, MDBFormInline, MDBBtn, MDBTable, MDBTableBody, MDBTableHead, MDBChip, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBInput, MDBIcon} from 'mdbreact';
import "./OrderTableList.css";
import order from "./Order";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'

import MeiryoRegular from "../../fonts/MeiryoRegular";
import MeiryoBold from "../../fonts/MeiryoBold";

import firebase from "../../firebase";
import Typesense from "typesense";
import nl2br from "react-nl2br";
const TYPESENSE_CONFIG = {
  nodes: [
    {
      host: process.env.REACT_APP_TYPESENSE_HOST,
      port: "443",
      protocol: "https",
    },
  ],
  apiKey: process.env.REACT_APP_TYPESENSE_ADMIN_API_KEY,
};
const typesense = new Typesense.Client(TYPESENSE_CONFIG);
class OrderTableList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dbOrders: [],
      loadingPDF: false,
      last: null,
      sortType: 'desc',
      orderType: '',
      notMore: false,
      orderPerPage: 12,
      userType: "",
      selectedOrderList: [],
      searchwordA: '',
      algoriaPage: 1,
      algolia: false,
    }
  }

  sortChange = (val) => {
    this.setState({last: null, notMore: false, sortType: val, dbOrders: [], selectedOrderList : [], algoriaPage: 1}, () => {
      if (this.state.algolia) {
        this.getOrdersA(this.props, true, this.state.searchwordA)
      } else {
        this.getOrders(this.props, true);
      }
    })
  }

  selectOrderType = (e) => {
    this.setState({last: null, notMore: false, orderType: e.target.value*1, dbOrders: [], selectedOrderList : [], algoriaPage: 1}, () => {
      if (this.state.algolia) {
        this.getOrdersA(this.props, true, this.state.searchwordA)
      } else {
        this.getOrders(this.props, true);
      }
    })
  }

  clearFilter = () => {
    this.setState({last: null, notMore: false, orderType: '', sortType: 'desc', searchwordA: '', algolia: false, dbOrders: [], selectedOrderList : [], algoriaPage: 1}, () => {
      if (this.state.algolia) {
        this.getOrdersA(this.props, true, this.state.searchwordA)
      } else {
        this.getOrders(this.props, true);
      }

    })
  }

  getOrdersA = async (props, newOrg, word) => {
    if (this.state.loading) {
      return;
    }

    if (this.state.notMore) {
      return;
    }

    let toOrg = "";
    let fromOrg = "";
    let urigawa = false;
    let userType = "";

    if(props.userOrg && props.userOrg.reseller && props.org && props.org.buyer) {
      userType = "seller";
      toOrg = this.props.user.organization;
      fromOrg = props.org.id;
      urigawa = true;
    }
    if(props.userOrg && props.userOrg.maker && props.org && props.org.reseller) {
      userType = "maker";
      toOrg = this.props.user.organization;
      fromOrg = props.org.id;
      urigawa = true;
    }
    if(props.userOrg && props.userOrg.reseller && props.org && props.org.maker) {
      userType = "sellerBuyer";
      toOrg = props.org.id;
      fromOrg = this.props.user.organization;
    }
    if(props.userOrg && props.userOrg.buyer && props.org && props.org.reseller) {
      userType = "buyer";
      toOrg = props.org.id;
      fromOrg = this.props.user.organization;
    }

    // console.log(userType)

    if (fromOrg == "" || toOrg == ""){
      return;
    }

    let tempResults = [];
    this.setState({loading: true, userType, algolia: true});
    let filters = ``;

    // indexName = ;
    // client.initIndex("P4Order")
    let sort_by = 'sortDate:desc';
    if(props.userOrg && props.userOrg.maker){
      switch (this.state.orderType) {
        case 1:
          if (this.state.sortType === 'desc') {
            //indexName = 'sort_latestUpdate_desc'
            sort_by = 'latestUpdate._seconds:desc'
          } else {
            //indexName = 'sort_latestUpdate_asc';
            sort_by = 'latestUpdate._seconds:asc'
          }
          filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg} && applied:true`
          break;
        case 2:
          if (this.state.sortType === 'desc') {
            //indexName = 'sort_sellerConfirmDate_desc'
            sort_by = 'sellerConfirmDate._seconds:desc'
          } else {
            //indexName = 'sort_sellerConfirmDate_asc'
            sort_by = 'sellerConfirmDate._seconds:asc'
          }
          filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg} && applied:true && sellerConfirm:true`
          break;
        case 3:
          if (this.state.sortType === 'desc') {
           //indexName = 'sort_sortDate_desc'
            sort_by = 'sortDate._seconds:desc'
          } else {
            //indexName = 'sort_sortDate_asc'
            sort_by = 'sortDate._seconds:asc'
          }
          filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg} && applied:true && sellerConfirm:false`
          break;
        case 4:
          if (this.state.sortType === 'desc') {
            sort_by = 'latestUpdate._seconds:desc'
          } else {
            sort_by = 'latestUpdate._seconds:asc'
          }
          filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg} && applied:true`
          break;
        case 5:
          if (this.state.sortType === 'desc') {
            sort_by = 'latestUpdate._seconds:desc'
          } else {
            sort_by = 'latestUpdate._seconds:asc'
          }
          filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg} && applied:true && isShipped:false`
          break;
        case 6:
          if (this.state.sortType === 'desc') {
            sort_by = 'latestUpdate._seconds:desc'
          } else {
            sort_by = 'latestUpdate._seconds:asc'
          }
          filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg} && applied:true && isShipped:true`
          break;
        default:
          if (this.state.sortType === 'desc') {
            sort_by = 'sortDate._seconds:desc'
          } else {
            sort_by = 'sortDate._seconds:asc'
          }
          filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg} && applied:true`
          break;
      }
    } else {
      if(urigawa){
        switch (this.state.orderType) {
          case 1:
            if (this.state.sortType === 'desc') {
              sort_by = 'latestUpdate._seconds:desc'
            } else {
              sort_by = 'latestUpdate._seconds:asc'
            }
            filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg} && applied:true`
            break;
          case 2:
            if (this.state.sortType === 'desc') {
              sort_by = 'sellerConfirmDate._seconds:desc'
            } else {
              sort_by = 'sellerConfirmDate._seconds:asc'
            }
            filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg} && applied:true && sellerConfirm:true`
            break;
          case 3:
            if (this.state.sortType === 'desc') {
              sort_by = 'sortDate._seconds:desc'
            } else {
              sort_by = 'sortDate._seconds:asc'
            }
            filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg} && applied:true && sellerConfirm:false`
            break;
          case 4:
            if (this.state.sortType === 'desc') {
              sort_by = 'latestUpdate._seconds:desc'
            } else {
              sort_by = 'latestUpdate._seconds:asc'
            }
            filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg} && applied:true`
            break;
          case 5:
            if (this.state.sortType === 'desc') {
              sort_by = 'latestUpdate._seconds:desc'
            } else {
              sort_by = 'latestUpdate._seconds:asc'
            }
            filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg} && applied:true && isShipped:false`
            break;
          case 6:
            if (this.state.sortType === 'desc') {
              sort_by = 'latestUpdate._seconds:desc'
            } else {
              sort_by = 'latestUpdate._seconds:asc'
            }
            filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg} && applied:true && isShipped:true`
            break;
          default:
            if (this.state.sortType === 'desc') {
              sort_by = 'sortDate._seconds:desc'
            } else {
              sort_by = 'sortDate._seconds:asc'
            }
            filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg} && applied:true`
            break;
        }
      } else {
        switch (this.state.orderType) {
          case 1:
            if (this.state.sortType === 'desc') {
              sort_by = 'latestUpdate._seconds:desc'
            } else {
              sort_by = 'latestUpdate._seconds:asc'
            }
            filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg} && newReject:false && newApply:false`
            break;
          case 2:
            if (this.state.sortType === 'desc') {
              sort_by = 'sortDate._seconds:desc'
            } else {
              sort_by = 'sortDate._seconds:asc'
            }
            filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg} && newApply:true`
            break;
          case 3:
            if (this.state.sortType === 'desc') {
              sort_by = 'latestUpdate._seconds:desc'
            } else {
              sort_by = 'latestUpdate._seconds:asc'
            }
            filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg} && newReject:true`
            break;
          case 4:
            if (this.state.sortType === 'desc') {
              sort_by = 'latestUpdate._seconds:desc'
            } else {
              sort_by = 'latestUpdate._seconds:asc'
            }
            filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg}`
            break;
          case 5:
            if (this.state.sortType === 'desc') {
              sort_by = 'latestUpdate._seconds:desc'
            } else {
              sort_by = 'latestUpdate._seconds:asc'
            }
            filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg} && isShipped:false`
            break;
          case 6:
            if (this.state.sortType === 'desc') {
              sort_by = 'latestUpdate._seconds:desc'
            } else {
              sort_by = 'latestUpdate._seconds:asc'
            }
            filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg} && isShipped:true`
            break;
          default:
            if (this.state.sortType === 'desc') {
              sort_by = 'sortDate._seconds:desc'
            } else {
              sort_by = 'sortDate._seconds:asc'
            }
            filters = `fromOrganization:${fromOrg} && toOrganization:${toOrg}`
            break;
        }
      }
    }
    let searchParameters = {
      'q'         : word,
      'query_by'  : 'cartItemNames,objectID,cart.orderNo,applyUserName,rejectUserName,approveUser,toUserName',
      'infix'     : 'always,off,off,fallback,fallback,fallback,fallback',
      //'prefix'    : 'true,false,false,false,false,false,false',
      //'prioritize_exact_match' : 'false',
      //'text_match_type': 'max_score',
      "num_typos": 0,
      "max_candidates": 1,
      "typo_tokens_threshold": 0,
      'filter_by' : filters,
      'sort_by'   : sort_by,
      'page'      : this.state.algoriaPage,
      'per_page'  : this.state.orderPerPage
    }

    try {
      await typesense.collections('orders').documents().search(searchParameters).then((docs) => {
        //docs.page=this.state.algoriaPage,
        docs.hits.forEach((data) => {
          tempResults.push(data.document);
        })
      });
    } catch (e) {
      console.log('error', e)
    }

    /*await client.initIndex(indexName)
        .search( word, {
          page: this.state.algoriaPage,
          filters: filters
        })
        .then(function(responses) {
          tempResults = responses.hits;
          // console.log(tempResults)
        });*/

    let collection;
    if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.maker) {
      // manufacturer
      collection = this.props.db.collection('items_stock').doc(this.props.userOrg.id).collection('buyers').doc(this.props.org.id).collection('items')
    } else if(this.props.org && this.props.org.maker && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/Purchase mode
      collection = this.props.db.collection('items_stock').doc(this.props.org.id).collection('buyers').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.buyer) {
      // purchasing user
      collection = this.props.db.collection('items_sell').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.buyer && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/sales mode
      collection = this.props.db.collection('items_sell').doc(this.props.org.id).collection('items')
    }

    let nextPage = this.state.algoriaPage + 1;
    let orders = this.state.dbOrders
    if (newOrg) {
      orders = [];
    }

    let notMore = false;
    if (tempResults.length === 0) {
      notMore = true
    }

    // console.log(tempResults.length, tempResults)

    for (let i=0; i<tempResults.length; i++) {
      let data = tempResults[i];

      let cart = data.cart;
      if (!data.newApply && !data.newReject) {
        delete cart['deletedItem'];
        delete cart['deletedText'];
        await Promise.all(cart.items.map(async (item) => {
          let itemSell = await collection.doc(item.id).get();
          let itemSellData = itemSell.data();

          delete item['deletedItem'];
          delete item['deletedText'];

          if (!itemSellData) {
            cart.deletedItem = true;
            cart.deletedText = '販売終了商品が含まれています';
            item.deletedItem = true;
            item.deletedText = '販売終了商品が含まれています';
          } else if (itemSellData && !itemSellData['price'] ||  itemSellData && itemSellData['price'] && itemSellData['price'][0] && !itemSellData['price'][0]['price']) {
            cart.deletedItem = true;
            cart.deletedText = '要見積商品が含まれています';
            item.deletedItem = true;
            item.deletedText = '要見積商品が含まれています';
          } else if (itemSellData['price'] && itemSellData['price'][0] && !itemSellData['price'][0]['expiration_date']) {
            cart.deletedItem = true;
            cart.deletedText = '要見積商品が含まれています';
            item.deletedItem = true;
            item.deletedText = '要見積商品が含まれています';
          } else if (itemSellData && itemSellData['price'] && itemSellData['price'][0] && itemSellData['price'][0]['expiration_date']) {
            let expiration_date = itemSellData['price'][0]['expiration_date'];
            let expDate = expiration_date.toDate();

            if ((expDate.getTime() - this.props.dateNow) < 0) {
              cart.deletedItem = true;
              cart.deletedText = '価格有効期限切れ要見積';
              item.deletedItem = true;
              item.deletedText = '価格有効期限切れ要見積';
            } else {
              item.price = itemSellData['price'][0]['price'];
            }
          } else {
            item.price = itemSellData['price'][0]['price'];
          }

          // if (itemSellData && itemSellData['price']) {
          //   item.price = itemSellData['price'][0]['price'];
          // }
        }));
      }

      orders.push(data)
    }

    // console.log(orders)
    // console.log('nextPage',nextPage);
    // let newResults = orders.concat(tempResults);

    this.setState({
      algoriaPage: nextPage,
      dbOrders: orders,
      loading: false,
      notMore: notMore
    });
  }

  getOrders = async (props, newOrg) => {
    console.log("###### getOrders ########", props, newOrg)

    if (this.state.loading) {
      return;
    }

    if (this.state.notMore) {
      return;
    }

    let toOrg = "";
    let fromOrg = "";
    let urigawa = false;
    let userType = "";

    if(props.userOrg && props.userOrg.reseller && props.org && props.org.buyer) {
      userType = "seller";
      toOrg = this.props.user.organization;
      fromOrg = props.org.id;
      urigawa = true;
    }
    if(props.userOrg && props.userOrg.maker && props.org && props.org.reseller) {
      userType = "maker";
      toOrg = this.props.user.organization;
      fromOrg = props.org.id;
      urigawa = true;
    }
    if(props.userOrg && props.userOrg.reseller && props.org && props.org.maker) {
      userType = "sellerBuyer";
      toOrg = props.org.id;
      fromOrg = this.props.user.organization;
    }
    if(props.userOrg && props.userOrg.buyer && props.org && props.org.reseller) {
      userType = "buyer";
      toOrg = props.org.id;
      fromOrg = this.props.user.organization;
    }

    // console.log(userType)

    if (fromOrg == "" || toOrg == ""){
      return;
    }

    let first;
    this.setState({loading: true, userType, algolia: false})

    if(props.userOrg && props.userOrg.maker){
      if(this.state.last){
        switch (this.state.orderType) {
          case 1:
            first = this.props.db.collection('orders')
                .where("fromOrganization", "==", fromOrg)
                .where("toOrganization", "==", toOrg)
                .where("applied", "==", true)
                // .where("newReject", "==", false)
                // .where("newApply", "==", false)
                .orderBy('latestUpdate', this.state.sortType)
                .startAfter(this.state.last)
                .limit(this.state.orderPerPage);
            break;
          case 2:
            first = this.props.db.collection('orders')
                .where("fromOrganization", "==", fromOrg)
                .where("toOrganization", "==", toOrg)
                .where("applied", "==", true)
                .where("sellerConfirm", "==", true)
                .orderBy('sellerConfirmDate', this.state.sortType)
                .startAfter(this.state.last)
                .limit(this.state.orderPerPage);
            break;
          case 3:
            first = this.props.db.collection('orders')
                .where("fromOrganization", "==", fromOrg)
                .where("toOrganization", "==", toOrg)
                .where("applied", "==", true)
                .where("sellerConfirm", "==", false)
                .orderBy('sortDate', this.state.sortType)
                .startAfter(this.state.last)
                .limit(this.state.orderPerPage);
            break;
          case 4:
            first = this.props.db.collection('orders')
                .where("fromOrganization", "==", fromOrg)
                .where("toOrganization", "==", toOrg)
                .where("applied", "==", true)
                .orderBy('latestUpdate', this.state.sortType)
                .startAfter(this.state.last)
                .limit(this.state.orderPerPage);
            break;
          case 5:
            first = this.props.db.collection('orders')
                .where("fromOrganization", "==", fromOrg)
                .where("toOrganization", "==", toOrg)
                .where("applied", "==", true)
                .where("isShipped", "==", false)
                .orderBy('latestUpdate', this.state.sortType)
                .startAfter(this.state.last)
                .limit(this.state.orderPerPage);
            break;
          case 6:

            first = this.props.db.collection('orders')
                .where("fromOrganization", "==", fromOrg)
                .where("toOrganization", "==", toOrg)
                .where("applied", "==", true)
                .where("isShipped", "==", true)
                .orderBy('latestUpdate', this.state.sortType)
                .startAfter(this.state.last)
                .limit(this.state.orderPerPage);
            break;
          default:
            first = this.props.db.collection('orders')
                .where("fromOrganization", "==", fromOrg)
                .where("toOrganization", "==", toOrg)
                .where("applied", "==", true)
                .orderBy('sortDate', this.state.sortType)
                .startAfter(this.state.last)
                .limit(this.state.orderPerPage);
            break;
        }

      }else{
        switch (this.state.orderType) {
          case 1:
            first = this.props.db.collection("orders")
                .where("fromOrganization", "==", fromOrg)
                .where("toOrganization", "==", toOrg)
                .where("applied", "==", true)
                // .where("newApply", "==", false)
                // .where("newReject", "==", false)
                .orderBy('latestUpdate', this.state.sortType)
                .limit(this.state.orderPerPage);
            break;
          case 2:
            // console.log("this maker load")
            first = this.props.db.collection("orders")
                .where("fromOrganization", "==", fromOrg)
                .where("toOrganization", "==", toOrg)
                .where("applied", "==", true)
                // .where("newApply", "==", true)
                .where("sellerConfirm", "==", true)
                .orderBy('sellerConfirmDate', this.state.sortType)
                .limit(this.state.orderPerPage);
            break;
          case 3:
            // console.log("this maker load 3")
            first = this.props.db.collection("orders")
                .where("fromOrganization", "==", fromOrg)
                .where("toOrganization", "==", toOrg)
                .where("applied", "==", true)
                // .where("newReject", "==", true)
                .where("sellerConfirm", "==", false)
                .orderBy('sortDate', this.state.sortType)
                .limit(this.state.orderPerPage);
            break;
          case 4:
            first = this.props.db.collection("orders")
                .where("fromOrganization", "==", fromOrg)
                .where("toOrganization", "==", toOrg)
                .where("applied", "==", true)
                .orderBy('latestUpdate', this.state.sortType)
                .limit(this.state.orderPerPage);
            break;
          case 5:
            first = this.props.db.collection('orders')
                .where("fromOrganization", "==", fromOrg)
                .where("toOrganization", "==", toOrg)
                .where("applied", "==", true)
                .where("isShipped", "==", false)
                .orderBy('latestUpdate', this.state.sortType)
                .limit(this.state.orderPerPage);
            break;
          case 6:
            first = this.props.db.collection('orders')
                .where("fromOrganization", "==", fromOrg)
                .where("toOrganization", "==", toOrg)
                .where("applied", "==", true)
                .where("isShipped", "==", true)
                .orderBy('latestUpdate', this.state.sortType)
                .limit(this.state.orderPerPage);
            break;
          default:
            first = this.props.db.collection("orders")
                .where("fromOrganization", "==", fromOrg)
                .where("toOrganization", "==", toOrg)
                .where("applied", "==", true)
                .orderBy('sortDate', this.state.sortType)
                .limit(this.state.orderPerPage);
            break;
        }

      }
    } else {
      if(urigawa){
        if(this.state.last){
          switch (this.state.orderType) {
            case 1:
              first = this.props.db.collection('orders')
                  .where("fromOrganization", "==", fromOrg)
                  .where("toOrganization", "==", toOrg)
                  .where("applied", "==", true)　// 承認済みのものだけ表示
                  // .where("newApply", "==", false)
                  // .where("newReject", "==", false)
                  .orderBy('latestUpdate', this.state.sortType)
                  .startAfter(this.state.last)
                  .limit(this.state.orderPerPage);
              break;
            case 2:
              first = this.props.db.collection('orders')
                  .where("fromOrganization", "==", fromOrg)
                  .where("toOrganization", "==", toOrg)
                  .where("applied", "==", true)　// 承認済みのものだけ表示
                  // .where("newApply", "==", true)
                  .where("sellerConfirm", "==", true)
                  .orderBy('sellerConfirmDate', this.state.sortType)
                  .startAfter(this.state.last)
                  .limit(this.state.orderPerPage);
              break;
            case 3:
              first = this.props.db.collection('orders')
                  .where("fromOrganization", "==", fromOrg)
                  .where("toOrganization", "==", toOrg)
                  .where("applied", "==", true)　// 承認済みのものだけ表示
                  // .where("newReject", "==", true)
                  .where("sellerConfirm", "==", false)
                  .orderBy('sortDate', this.state.sortType)
                  .startAfter(this.state.last)
                  .limit(this.state.orderPerPage);
              break;
            case 4:
              first = this.props.db.collection('orders')
                  .where("fromOrganization", "==", fromOrg)
                  .where("toOrganization", "==", toOrg)
                  .where("applied", "==", true)　// 承認済みのものだけ表示
                  .orderBy('latestUpdate', this.state.sortType)
                  .startAfter(this.state.last)
                  .limit(this.state.orderPerPage);
              break;
            case 5:
              first = this.props.db.collection('orders')
                  .where("fromOrganization", "==", fromOrg)
                  .where("toOrganization", "==", toOrg)
                  .where("applied", "==", true)
                  .where("isShipped", "==", false)
                  .orderBy('latestUpdate', this.state.sortType)
                  .startAfter(this.state.last)
                  .limit(this.state.orderPerPage);
              break;
            case 6:

              first = this.props.db.collection('orders')
                  .where("fromOrganization", "==", fromOrg)
                  .where("toOrganization", "==", toOrg)
                  .where("applied", "==", true)
                  .where("isShipped", "==", true)
                  .orderBy('latestUpdate', this.state.sortType)
                  .startAfter(this.state.last)
                  .limit(this.state.orderPerPage);
              break;
            default:
              first = this.props.db.collection('orders')
                  .where("fromOrganization", "==", fromOrg)
                  .where("toOrganization", "==", toOrg)
                  .where("applied", "==", true)　// 承認済みのものだけ表示
                  .orderBy('sortDate', this.state.sortType)
                  .startAfter(this.state.last)
                  .limit(this.state.orderPerPage);
              break;
          }

        }else{
          // console.log('seller list', this.state.orderType)
          switch (this.state.orderType) {
            case 1:
              first = this.props.db.collection("orders")
                  .where("fromOrganization", "==", fromOrg)
                  .where("toOrganization", "==", toOrg)
                  .where("applied", "==", true)　// 承認済みのものだけ表示
                  // .where("newApply", "==", false)
                  // .where("newReject", "==", false)
                  .orderBy('latestUpdate', this.state.sortType)
                  .limit(this.state.orderPerPage);
              break;
            case 2:
              first = this.props.db.collection("orders")
                  .where("fromOrganization", "==", fromOrg)
                  .where("toOrganization", "==", toOrg)
                  .where("applied", "==", true)　// 承認済みのものだけ表示
                  // .where("newApply", "==", true)
                  .where("sellerConfirm", "==", true)
                  .orderBy('sellerConfirmDate', this.state.sortType)
                  .limit(this.state.orderPerPage);
              break;
            case 3:
              first = this.props.db.collection("orders")
                  .where("fromOrganization", "==", fromOrg)
                  .where("toOrganization", "==", toOrg)
                  .where("applied", "==", true)　// 承認済みのものだけ表示
                  // .where("newReject", "==", true)
                  .where("sellerConfirm", "==", false)
                  .orderBy('sortDate', this.state.sortType)
                  .limit(this.state.orderPerPage);
              break;
            case 4:
              first = this.props.db.collection("orders")
                  .where("fromOrganization", "==", fromOrg)
                  .where("toOrganization", "==", toOrg)
                  .where("applied", "==", true)　// 承認済みのものだけ表示
                  .orderBy('latestUpdate', this.state.sortType)
                  .limit(this.state.orderPerPage);
              break;
            case 5:
              first = this.props.db.collection('orders')
                  .where("fromOrganization", "==", fromOrg)
                  .where("toOrganization", "==", toOrg)
                  .where("applied", "==", true)
                  .where("isShipped", "==", false)
                  .orderBy('latestUpdate', this.state.sortType)
                  .limit(this.state.orderPerPage);
              break;
            case 6:
              first = this.props.db.collection('orders')
                  .where("fromOrganization", "==", fromOrg)
                  .where("toOrganization", "==", toOrg)
                  .where("applied", "==", true)
                  .where("isShipped", "==", true)
                  .orderBy('latestUpdate', this.state.sortType)
                  .limit(this.state.orderPerPage);
              break;
            default:
              first = this.props.db.collection("orders")
                  .where("fromOrganization", "==", fromOrg)
                  .where("toOrganization", "==", toOrg)
                  .where("applied", "==", true)　// 承認済みのものだけ表示
                  .orderBy('sortDate', this.state.sortType)
                  .limit(this.state.orderPerPage);
              break;
          }
        }
      }else{
        if(this.state.last){
          switch (this.state.orderType) {
            case 1:
              first = this.props.db.collection('orders')
                  .where("fromOrganization", "==", fromOrg)
                  .where("toOrganization", "==", toOrg)
                  .where("newReject", '==', false)
                  .where("newApply", '==', false)
                  .orderBy('latestUpdate', this.state.sortType)
                  .startAfter(this.state.last)
                  .limit(this.state.orderPerPage);
              break;
            case 2:
              first = this.props.db.collection('orders')
                  .where("fromOrganization", "==", fromOrg)
                  .where("toOrganization", "==", toOrg)
                  .where("newApply", '==', true)
                  .orderBy('sortDate', this.state.sortType)
                  .startAfter(this.state.last)
                  .limit(this.state.orderPerPage);
              break;
            case 3:
              first = this.props.db.collection('orders')
                  .where("fromOrganization", "==", fromOrg)
                  .where("toOrganization", "==", toOrg)
                  .where("newReject", '==', true)
                  .orderBy('latestUpdate', this.state.sortType)
                  .startAfter(this.state.last)
                  .limit(this.state.orderPerPage);
              break;
            case 4:
              first = this.props.db.collection('orders')
                  .where("fromOrganization", "==", fromOrg)
                  .where("toOrganization", "==", toOrg)
                  .orderBy('latestUpdate', this.state.sortType)
                  .startAfter(this.state.last)
                  .limit(this.state.orderPerPage);
              break;
            case 5:
              first = this.props.db.collection('orders')
                  .where("fromOrganization", "==", fromOrg)
                  .where("toOrganization", "==", toOrg)
                  .where("isShipped", "==", false)
                  .orderBy('latestUpdate', this.state.sortType)
                  .startAfter(this.state.last)
                  .limit(this.state.orderPerPage);
              break;
            case 6:
              first = this.props.db.collection('orders')
                  .where("fromOrganization", "==", fromOrg)
                  .where("toOrganization", "==", toOrg)
                  .where("isShipped", "==", true)
                  .orderBy('latestUpdate', this.state.sortType)
                  .startAfter(this.state.last)
                  .limit(this.state.orderPerPage);
              break;
            default:
              first = this.props.db.collection('orders')
                  .where("fromOrganization", "==", fromOrg)
                  .where("toOrganization", "==", toOrg)
                  .orderBy('sortDate', this.state.sortType)
                  .startAfter(this.state.last)
                  .limit(this.state.orderPerPage);
              break;
          }


        }else{
          switch (this.state.orderType) {
            case 1:
              first = this.props.db.collection("orders")
                  .where("fromOrganization", "==", fromOrg)
                  .where("toOrganization", "==", toOrg)
                  .where("newReject", '==', false)
                  .where("newApply", '==', false)
                  .orderBy('latestUpdate', this.state.sortType)
                  .limit(this.state.orderPerPage);
              break;
            case 2:
              first = this.props.db.collection("orders")
                  .where("fromOrganization", "==", fromOrg)
                  .where("toOrganization", "==", toOrg)
                  .where("newApply", '==', true)
                  .orderBy('sortDate', this.state.sortType)
                  .limit(this.state.orderPerPage);
              break;
            case 3:
              first = this.props.db.collection("orders")
                  .where("fromOrganization", "==", fromOrg)
                  .where("toOrganization", "==", toOrg)
                  .where("newReject", '==', true)
                  .orderBy('latestUpdate', this.state.sortType)
                  .limit(this.state.orderPerPage);
              break;
            case 4:
              first = this.props.db.collection("orders")
                  .where("fromOrganization", "==", fromOrg)
                  .where("toOrganization", "==", toOrg)
                  .orderBy('latestUpdate', this.state.sortType)
                  .limit(this.state.orderPerPage);
              break;
            case 5:
              first = this.props.db.collection('orders')
                  .where("fromOrganization", "==", fromOrg)
                  .where("toOrganization", "==", toOrg)
                  .where("isShipped", "==", false)
                  .orderBy('latestUpdate', this.state.sortType)
                  .limit(this.state.orderPerPage);
              break;
            case 6:
              first = this.props.db.collection('orders')
                  .where("fromOrganization", "==", fromOrg)
                  .where("toOrganization", "==", toOrg)
                  .where("isShipped", "==", true)
                  .orderBy('latestUpdate', this.state.sortType)
                  .limit(this.state.orderPerPage);
              break;
            default:
              first = this.props.db.collection("orders")
                  .where("fromOrganization", "==", fromOrg)
                  .where("toOrganization", "==", toOrg)
                  .orderBy('sortDate', this.state.sortType)
                  .limit(this.state.orderPerPage);
              break;
          }

        }
      }
    }

    let collection;
    if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.maker) {
      // manufacturer
      collection = this.props.db.collection('items_stock').doc(this.props.userOrg.id).collection('buyers').doc(this.props.org.id).collection('items')
    } else if(this.props.org && this.props.org.maker && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/Purchase mode
      collection = this.props.db.collection('items_stock').doc(this.props.org.id).collection('buyers').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.buyer) {
      // purchasing user
      collection = this.props.db.collection('items_sell').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.buyer && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/sales mode
      collection = this.props.db.collection('items_sell').doc(this.props.org.id).collection('items')
    }

    let o = await first.get();

    if (o.docs.length > 0 && collection) {
      let last = o.docs[o.docs.length - 1];

      this.setState({last: last});

      let orders = this.state.dbOrders;
      if (newOrg) {
        orders = [];
      }

      for (let i=0; i<o.docs.length; i++) {
        let doc = o.docs[i];
        let data = doc.data();
        data.id = doc.id;

        // console.log(fromOrg, toOrg, data.id, data)

        let cart = data.cart;
        if (!data.newApply && !data.newReject) {
          delete cart['deletedItem'];
          delete cart['deletedText'];
          await Promise.all(cart.items.map(async (item) => {
            let itemSell = await collection.doc(item.id).get();
            let itemSellData = itemSell.data();

            delete item['deletedItem'];
            delete item['deletedText'];

            if (!itemSellData) {
              cart.deletedItem = true;
              cart.deletedText = '販売終了商品が含まれています';
              item.deletedItem = true;
              item.deletedText = '販売終了商品が含まれています';
            } else if (itemSellData && !itemSellData['price'] ||  itemSellData && itemSellData['price'] && itemSellData['price'][0] && !itemSellData['price'][0]['price']) {
              cart.deletedItem = true;
              cart.deletedText = '要見積商品が含まれています';
              item.deletedItem = true;
              item.deletedText = '要見積商品が含まれています';
            } else if (itemSellData['price'] && itemSellData['price'][0] && !itemSellData['price'][0]['expiration_date']) {
              cart.deletedItem = true;
              cart.deletedText = '要見積商品が含まれています';
              item.deletedItem = true;
              item.deletedText = '要見積商品が含まれています';
            } else if (itemSellData && itemSellData['price'] && itemSellData['price'][0] && itemSellData['price'][0]['expiration_date']) {
              let expiration_date = itemSellData['price'][0]['expiration_date'];
              let expDate = expiration_date.toDate();

              if ((expDate.getTime() - this.props.dateNow) < 0) {
                cart.deletedItem = true;
                cart.deletedText = '価格有効期限切れ要見積';
                item.deletedItem = true;
                item.deletedText = '価格有効期限切れ要見積';
              } else {
                item.price = itemSellData['price'][0]['price'];
              }
            } else {
              item.price = itemSellData['price'][0]['price'];
            }
            // if (itemSellData && itemSellData['price']) {
            //   item.price = itemSellData['price'][0]['price'];
            // }
          }));
        }


        orders.push(data)
      }

      this.setState({dbOrders: orders, loading: false}, () => {
        let wrapper1 = document.getElementById('wrapper1');
        let div1 = document.getElementById('div1');
        let wrapper2 = document.getElementsByClassName('table-responsive')[0];
        let div2 = document.getElementById('div2');

        if (wrapper1 && div1 && wrapper2 && div2) {
          div1.style.width = div2.offsetWidth+'px';

          wrapper1.onscroll = function() {
            wrapper2.scrollLeft = wrapper1.scrollLeft;
          };
          wrapper2.onscroll = function() {
            wrapper1.scrollLeft = wrapper2.scrollLeft;
          };
        }

      })
    } else {
      this.setState({notMore: true, loading: false});
      if (newOrg === true) {
        this.setState({dbOrders: []})
      }
    }


    // let paginate = first.get()
    // .then((snapshot) => {
    //   this.setState({loading: false})
    //   console.log('snapshot length:' + snapshot.docs.length);
    //   if(snapshot.docs.length > 0) {
    //     let last = snapshot.docs[snapshot.docs.length - 1];
    //
    //     this.setState({last: last})
    //
    //     let orders = this.state.dbOrders;
    //     if (newOrg) {
    //       orders = [];
    //     }
    //     snapshot.forEach((doc) => {
    //       let data = doc.data();
    //       data.id = doc.id;
    //       orders.push(data)
    //     })
    //     // console.log('orders:' + orders);
    //     this.setState({dbOrders: orders}, () => {
    //       let wrapper1 = document.getElementById('wrapper1');
    //       let div1 = document.getElementById('div1');
    //       let wrapper2 = document.getElementsByClassName('table-responsive')[0];
    //       let div2 = document.getElementById('div2');
    //
    //       if (wrapper1 && div1 && wrapper2 && div2) {
    //         div1.style.width = div2.offsetWidth+'px';
    //
    //         wrapper1.onscroll = function() {
    //           wrapper2.scrollLeft = wrapper1.scrollLeft;
    //         };
    //         wrapper2.onscroll = function() {
    //           wrapper1.scrollLeft = wrapper2.scrollLeft;
    //         };
    //       }
    //
    //     })
    //   }else{
    //     this.setState({notMore: true});
    //     if (newOrg === true) {
    //       this.setState({dbOrders: []})
    //     }
    //
    //   }
    //
    // });

  }

  getAllOrders = async () => {
    let orders = await this.props.db.collection('orders').orderBy('latestUpdate', 'desc').get();
    console.log('all orders', orders, orders.docs, orders.docs.length);
    //TODO: update all order to isShipped: false

    // let promises = [];
    //
    // for (let i=0; i<orders.docs.length; i++) {
    //   let doc = orders.docs[i];
    //   let data = doc.data();
    //   if (data.isShipped !== true && data.isShipped !== false) {
    //     console.log(doc.data())
    //     promises.push(doc.ref.set({isShipped: false,}, { merge: true }))
    //   }
    // }
    //
    // await Promise.all(promises);
    // console.log('success update all isShipped: false')

    // //TODO: check isShipped
    let promises = [];

    for (let i=0; i<orders.docs.length; i++) {
      let doc = orders.docs[i];
      let data = doc.data();
      let cart = data.cart;
      let items = cart.items;

      let isShippedAll = false;
      let shippedItemCount = 0;
      for (let a=0; a<items.length; a++) {
        let item = items[a];
        if (item.okurijyoNo) {
          let okurijyoNo = item.okurijyoNo.slice(-1)[0].value;
          let hassouDate = item.okurijyoNo.slice(-1)[0].hassouDate ? item.okurijyoNo.slice(-1)[0].hassouDate.toDate() : '';
          let shippingDate = item.okurijyoNo.slice(-1)[0].shippingDate;
          if (okurijyoNo && hassouDate && shippingDate) {
            // is shipped item
            shippedItemCount++;
          } else {
            // not shipped item
            break;
          }
        } else {
          // not shipped item
          break;
        }
      }

      if (shippedItemCount === items.length && data.isShipped === false) {
        // console.log('all shipped data', data)
        promises.push(doc.ref.set({isShipped: true,}, { merge: true }))
      }
    }

    console.log('start update all isShipped', promises.length)
    await Promise.all(promises);
    console.log('success update all isShipped: true');

  }

  // clearEmptyItem = async () => {
  //   console.log('clear empty item');
  //   let orders = await this.props.db.collection('orders').orderBy('latestUpdate', 'desc').get();
  //   console.log('orders', orders.docs.length);
  //
  //   let promises = [];
  //
  //   for (let i=0; i<orders.docs.length; i++) { //orders.docs.length
  //     let doc = orders.docs[i];
  //     let data = doc.data();
  //
  //     let result = data.cart.items.filter(item => item.count > 0);
  //     if (result.length === 0) {
  //       console.log('delete order', doc.id, data.cart.items)
  //     }
  //
  //     // let resultEmptyItem = data.cart.items.filter(item => item.count === 0);
  //     if (result.length !== data.cart.items.length) {
  //       let orderCart = data.cart;
  //       orderCart.items = result;
  //       console.log('delete order item', doc.id, data, orderCart);
  //       promises.push(doc.ref.set({cart: orderCart}, { merge: true }))
  //     }
  //   }
  //   // await Promise.all(promises);
  //   console.log('done', promises.length)
  // }

  // tax8 = async () => {
  //   let orders = await this.props.db.collection('orders').orderBy('latestUpdate', 'desc').get();
  //   orders.forEach(value => {
  //     let order = value.data();
  //     let cart = order.cart;
  //     let items = cart.items;
  //     // console.log(order)
  //
  //     if (order.isShipped === true) {
  //       items.map(async (item) => {
  //         // console.log(item.id)
  //         let fromOrgItemSell = await this.props.db.collection('items_sell').doc(order.fromOrganization).collection('items').doc(item.id).get();
  //         let toOrgItemSell = await this.props.db.collection('items_sell').doc(order.toOrganization).collection('items').doc(item.id).get();
  //         // let fromOrgItemStock = await this.props.db.collection('items_stock').doc(order.fromOrganization).collection('buyers').doc(order.toOrganization).collection('items').doc(item.id).get();
  //         // let toOrgItemStock = await this.props.db.collection('items_stock').doc(order.toOrganization).collection('buyers').doc(order.fromOrganization).collection('items').doc(item.id).get();
  //
  //
  //         let foItemSell = fromOrgItemSell.data();
  //         let toItemSell = toOrgItemSell.data();
  //         // let foItemStock = fromOrgItemStock.data();
  //         // let toItemStock = toOrgItemStock.data()
  //
  //         if (foItemSell) {
  //           let tax = foItemSell['消費税率']
  //           if (tax === 10 || tax === 0.08) {
  //             console.log('fo', value.id, order.fromOrganization, item.id, tax)
  //           } else {
  //             console.log('fo', tax)
  //           }
  //         }
  //         if (toItemSell) {
  //           let tax = toItemSell['消費税率']
  //           console.log('to', tax)
  //         }
  //
  //         // if (foItemStock) {
  //         //   let tax = foItemStock['消費税率'];
  //         //   console.log('foStock', tax)
  //         // }
  //         //
  //         // if (toItemStock) {
  //         //   let tax = toItemStock['消費税率'];
  //         //   console.log('toStock', tax)
  //         // }
  //       })
  //     }
  //
  //   })
  //   // console.log('all orders', orders, orders.docs, orders.docs.length);
  // }

  cartTax = async () => {
    let carts = this.props.db.collection('carts');
    let cartsGet = await carts.get();
    let cartsDocs = cartsGet.docs;
    let cartsData = await Promise.all(cartsDocs.map(async (value) => {
      try {
        let carts1 = carts.doc(value.id).collection('carts');
        let carts1Get = await carts1.get()
        let carts1Docs = carts1Get.docs;

        return {id: value.id, data: carts1Docs};
      } catch (e) {
        console.log('error', e)
      }
    }))

    Array(5).map((v, i) => {
      console.log('1',v, i)
    })

    await Promise.all(cartsData.map(async (value) => {
      await Promise.all(value.data.map(async value1 => {
        let data = value1.data()
        let user = await this.props.db.collection('users').doc(value.id).get();
        let userData = user.data();

        if (userData) {
          // console.log(userData.organization)
          // console.log(value.id, value1.id, data);
          await Promise.all([0,1,2,3,4].map(async (a) => {
            if (data[a] && Object.keys(data[a]).length > 0) {
              let items = data[a].items;

              await Promise.all(items.map(async (item, index) => {
                let itemData = null;
                let findItem = await this.props.db.collection('items_sell').doc(userData.organization).collection('items').doc(item.id).get();


                if (findItem.data()) {
                  itemData = findItem.data();
                  console.log('findItem', itemData['消費税率']);
                } else {

                  let findItemStock = await this.props.db.collection('items_stock').doc(value1.id).collection('buyers').doc(userData.organization).collection('items').doc(item.id).get();


                  if (findItemStock.data()) {
                    itemData = findItemStock.data();
                    console.log('findItemStock', itemData['消費税率']);
                  } else {
                    console.log('not found')
                  }

                }

                if (itemData) {
                  item.tax = itemData['消費税率'];
                }
              }))
            }
          }))

          // console.log(value.id, value1.id, data)
          await this.props.db.collection('carts').doc(value.id).collection('carts').doc(value1.id).set(data, {merge: true})
        }
      }))
    }))
  }

  otasukeCartTax = async () => {
    let carts = this.props.db.collection('otasuke_carts');
    let cartsGet = await carts.get();
    let cartsDocs = cartsGet.docs;
    let cartsData = await Promise.all(cartsDocs.map(async (value) => {
      try {
        let carts1 = carts.doc(value.id).collection('otasuke_carts');
        let carts1Get = await carts1.get()
        let carts1Docs = carts1Get.docs;

        return {id: value.id, data: carts1Docs};
      } catch (e) {
        console.log('error', e)
      }
    }))

    await Promise.all(cartsData.map(async (value) => {
      await Promise.all(value.data.map(async value1 => {
        let data = value1.data()
        // console.log(value.id, value1.id, data);
        let user = await this.props.db.collection('users').doc(value.id).get();
        let userData = user.data();

        if (userData) {
          // console.log(userData.organization)
          await Promise.all([0,1,2,3,4].map(async (a) => {
            if (data[a] && Object.keys(data[a]).length > 0) {
              let items = data[a].items;

              await Promise.all(items.map(async (item, index) => {
                // console.log('item',item)
                let itemData = null;
                let findItem = await this.props.db.collection('items_sell').doc(value1.id).collection('items').doc(item.id).get();

                if (findItem.data()) {
                  itemData = findItem.data();
                  console.log('findItem', itemData['消費税率']);
                } else {
                  let findItemStock = await this.props.db.collection('items_stock').doc(userData.organization).collection('buyers').doc(value1.id).collection('items').doc(item.id).get();

                  if (findItemStock.data()) {
                    itemData = findItemStock.data();
                    console.log('findItemStock', itemData['消費税率']);
                  } else {
                    console.log('not found')
                  }

                }

                if (itemData) {
                  item.tax = itemData['消費税率'];
                }
              }))
            }
          }))

          console.log(value.id, value1.id, data)
          await this.props.db.collection('otasuke_carts').doc(value.id).collection('otasuke_carts').doc(value1.id).set(data, {merge: true})
        }
      }))
    }))
  }

  otasukeCartHistoryTax = async () => {
    let carts = this.props.db.collection('otasuke_carts_history');
    let cartsGet = await carts.get();
    let cartsDocs = cartsGet.docs;
    await Promise.all(cartsDocs.map(async (value) => {
      try {
        let data = value.data();
        let cart = data.cart;
        let items = cart.items;
        console.log(data)

        await Promise.all(items.map(async (item, index) => {
          let itemData = null;
          let findItem = await this.props.db.collection('items_sell').doc(data.toOrganization).collection('items').doc(item.id).get();

          if (findItem.data()) {
            itemData = findItem.data();
            console.log('findItem', itemData['消費税率']);
          } else {
            let findItemStock = await this.props.db.collection('items_stock').doc(data.fromOrganization).collection('buyers').doc(data.toOrganization).collection('items').doc(item.id).get();

            if (findItemStock.data()) {
              itemData = findItemStock.data();
              console.log('findItemStock', itemData['消費税率']);
            } else {
              console.log('not found')
            }
          }

          if (itemData) {
            item.tax = itemData['消費税率'];
          }
        }));

        console.log(data.fromOrganization, data.toOrganization, data);
        await this.props.db.collection('otasuke_carts_history').doc(value.id).set(data, {merge: true})
      } catch (e) {
        console.log('error', e)
      }
    }))
  }

  orderTax = async () => {
    let orders = this.props.db.collection('orders');
    let ordersGet = await orders.get();
    let ordersDocs = ordersGet.docs;
    console.log('ordersDocs length', ordersDocs.length)
    await Promise.all(ordersDocs.map(async (value) => {
      try {
        let data = value.data();
        let cart = data.cart;
        if (cart) {
          let items = cart.items;

          await Promise.all(items.map(async (item) => {
            let itemData = null;
            let findItem = await this.props.db.collection('items_sell').doc(data.toOrganization).collection('items').doc(item.id).get();

            if (findItem.data()) {
              itemData = findItem.data();
              console.log('findItem', itemData['消費税率']);
            } else {
              let findItemStock = await this.props.db.collection('items_stock').doc(data.fromOrganization).collection('buyers').doc(data.toOrganization).collection('items').doc(item.id).get();
              if (findItemStock.data()) {
                itemData = findItemStock.data();
                console.log('findItemStock', itemData['消費税率']);
              } else {
                let findItem1 = await this.props.db.collection('items_sell').doc(data.fromOrganization).collection('items').doc(item.id).get();
                if (findItem1.data()) {
                  itemData = findItem1.data();
                  console.log('findItem1', itemData['消費税率']);
                } else {
                  let findItemStock1 = await this.props.db.collection('items_stock').doc(data.toOrganization).collection('buyers').doc(data.fromOrganization).collection('items').doc(item.id).get();
                  if (findItemStock1.data()) {
                    itemData = findItemStock1.data();
                    console.log('findItemStock1', itemData['消費税率']);
                  } else {
                    console.log('not found')
                  }
                }
              }

            }

            if (itemData) {
              item.tax = itemData['消費税率'] || 0.1;
            }

          }))
          console.log(value.id, data)
          await this.props.db.collection('orders').doc(value.id).set(data, {merge: true})
        }

      } catch (e) {
        console.log('error', e)
      }
    }))
  }

  orderComment = async () => {
    let orders = this.props.db.collection('orders').orderBy('latestUpdate', 'desc').limit(100);
    let ordersGet = await orders.get();
    let ordersDocs = ordersGet.docs;
    console.log('ordersDocs length', ordersDocs.length)
    await Promise.all(ordersDocs.map(async (value) => {
      try {
        let data = value.data();
        let cart = data.cart;
        if (cart) {
          let items = cart.items;
          let withComment = false;
          await Promise.all(items.map(async (item) => {
            if (item.noukiComment || item.okurijyoComment) {
              withComment = true;
            }
          }))
          if (withComment === false) {
            delete data.readComment;
          }
          console.log(value.id, data)
          await this.props.db.collection('orders').doc(value.id).set(data)
        }

      } catch (e) {
        console.log('error', e)
      }
    }))
    console.log('order comment done')
  }

  favoriteCartTax = async () => {
    // this.props.db.collection('favorite_set').doc(this.props.user.id).collection('favorite_set').doc(this.props.org.id);
    let carts = this.props.db.collection('favorite_set');
    let cartsGet = await carts.get();
    let cartsDocs = cartsGet.docs;
    let cartsData = await Promise.all(cartsDocs.map(async (value) => {
      try {
        let carts1 = carts.doc(value.id).collection('favorite_set');
        let carts1Get = await carts1.get();
        let carts1Docs = carts1Get.docs;

        return {id: value.id, data: carts1Docs}
      } catch (e) {
        console.log('error', e)
      }
    }))

    await Promise.all(cartsData.map(async (value) => {
      await Promise.all(value.data.map(async value1 => {
        let data = value1.data();
        // console.log(data)

        let user = await this.props.db.collection('users').doc(value.id).get();
        let userData = user.data();

        if (userData) {
          await Promise.all([0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29].map(async (a) => {
            if (data[a] && Object.keys(data[a]).length > 0) {
              let items = data[a].items;

              await Promise.all(items.map(async (item, index) => {
                let itemData = null;
                let findItem = await this.props.db.collection('items_sell').doc(value1.id).collection('items').doc(item.id).get();

                if (findItem.data()) {
                  itemData = findItem.data();
                  console.log('findItem', itemData['消費税率']);
                } else {
                  let findItemStock = await this.props.db.collection('items_stock').doc(userData.organization).collection('buyers').doc(value1.id).collection('items').doc(item.id).get();
                  if (findItemStock.data()) {
                    itemData = findItemStock.data();
                    console.log('findItemStock', itemData['消費税率']);
                  } else {
                    let findItem1 = await this.props.db.collection('items_sell').doc(userData.organization).collection('items').doc(item.id).get();
                    if (findItem1.data()) {
                      itemData = findItem1.data();
                      console.log('findItem1', itemData['消費税率']);
                    } else {
                      let findItemStock1 = await this.props.db.collection('items_stock').doc(value1.id).collection('buyers').doc(userData.organization).collection('items').doc(item.id).get();
                      if (findItemStock1.data()) {
                        itemData = findItemStock1.data();
                        console.log('findItemStock1', itemData['消費税率']);
                      } else {
                        console.log('not found')
                      }
                    }
                  }

                }

                if (itemData) {
                  item.tax = itemData['消費税率'];
                }
              }));
            }
          }));

          // console.log(value.id, value1.id, data);

          await this.props.db.collection('favorite_set').doc(value.id).collection('favorite_set').doc(value1.id).set(data, {merge: true})
        }
      }))
    }))
  }

  orderShippedStatus = async () => {
    let orders = this.props.db.collection('orders').orderBy('latestUpdate', 'desc').limit(1000);
    let ordersGet = await orders.get();
    let ordersDocs = ordersGet.docs;
    console.log('ordersDocs length', ordersDocs.length)
    await Promise.all(ordersDocs.map(async (value) => {
      try {
        let data = value.data();
        let cart = data.cart;
        if (cart) {
          let items = cart.items;
          let shippedItemCount = 0;
          let possibleShippedItemCount = 0;
          await Promise.all(items.map(async (item) => {
            if (!item.divided) {
              possibleShippedItemCount += 1;
            }

            if (item.okurijyoNo) {
              let okurijyoNo = item.okurijyoNo.slice(-1)[0].value;
              let hassouDate = item.okurijyoNo.slice(-1)[0].hassouDate ? item.okurijyoNo.slice(-1)[0].hassouDate.toDate() : '';
              let shippingDate = item.okurijyoNo.slice(-1)[0].shippingDate;
              if (okurijyoNo && hassouDate && shippingDate) {
                shippedItemCount++;
              }
            }
          }))

          if (shippedItemCount === possibleShippedItemCount) {
            data['isShipped'] = true;
          }
          // console.log(value.id, data);
          await this.props.db.collection('orders').doc(value.id).set(data)
        }

      } catch (e) {
        console.log('error', e)
      }
    }))
    console.log('order shipped status done')
  }

  componentDidMount() {
    //this.getOrders();
    this.getOrders(this.props);
    this.props.loadBadge();
    // this.tax8();
    // this.getAllOrders();
    // this.clearEmptyItem();

    // this.cartTax();
    // this.otasukeCartTax();
    // this.otasukeCartHistoryTax();
    // this.favoriteCartTax();
    // this.orderTax();
    // this.orderComment();
    // this.orderShippedStatus();
    window.addEventListener('scroll', event => this.watchCurrentPosition(), true);
  }
  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps', nextProps);
    // if (this.props.org !== nextProps.org) {
    //   // console.log('new org')
    //   this.setState({last: null, notMore: false}, () => {
    //     this.getOrders(nextProps, true);
    //   });
    // }
    // else {
    //   this.setState({last: null, notMore: false}, () => {
    //     console.log('old org', this.state.last)
    //     this.getOrders(nextProps, false);
    //   });
    // }

    this.setState({
      last: null,
      notMore: false,
      algolia: false,
      searchwordA: '',
      algoriaPage: 1,
      dbOrders: [],
      selectedOrderList : [],
      sortType: 'desc',
      orderType: ''
    }, () => {
      this.getOrders(nextProps, true);
      // console.log('loadBadge willReceive')
      this.props.loadBadge();
    });
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', event => this.watchCurrentPosition(), true);
  }

  watchCurrentPosition() {
    if(window.innerHeight + this.scrollTop() +1 >= document.body.clientHeight) {
    // if(this.state.notMore === false && window.innerHeight + this.scrollTop() +20 >= document.body.clientHeight) {
      console.log('reach end');
      this.onReachEnd();
    }
  }

  scrollTop() {
    return Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
        );
  }

  onReachEnd = () => {
    // console.log("LAST:" , this.state.last);
    if (this.state.loading === false) {
      if(this.state.algolia){
        this.getOrdersA(this.props, false, this.state.searchwordA)
      } else {
        this.getOrders(this.props);
      }
    }


  }

  detail = (id, index) => {
    let orders = this.state.dbOrders;
    let order = orders[index];
    // console.log('order', order);
    if (this.state.userType === "buyer" || this.state.userType === "sellerBuyer") {
      let setObject = {};
      if (order.readBuyer === false) {
        setObject.readBuyer = true;
        this.props.readOrder(1);
      }
      if (order.readComment === false) {
        setObject.readComment = true;
      }

      // this.dbOrdersUpdate(id,"readBuyer",true);
      // this.dbOrdersUpdate(id,"readComment",true);

      let ordersRef = this.props.db.collection('orders').doc(id);
      ordersRef.set(setObject, { merge: true })
          .then(() => {
            // this.props.cProps.history.push("/order/" + id);
            window.open("/order/" + id, "_blank");
          })
    } if (this.state.userType === 'seller' || this.state.userType === 'maker') {
      let setObject = {};
      if (order.readSeller === false) {
        setObject.readSeller = true;
      }
      let ordersRef = this.props.db.collection('orders').doc(id);
      ordersRef.set(setObject, { merge: true })
          .then(() => {
            window.open("/order/" + id, "_blank");
          })
    } else {
      window.open("/order/" + id, "_blank");
    }}



  openFile = (e) => {
    console.log("openFile:" , e.currentTarget.dataset.id);
    /*
    let esId = e.currentTarget.dataset.id;

    let ordersRef = this.props.db.collection('orders').doc(esId);
    ordersRef.set({
      visited: {[this.props.user.id]: true},
    }, { merge: true });

    if(this.props.user.authorizer){
      ordersRef.set({
        authorizer_visited: true,
      }, { merge: true });
    }
    this.dbOrdersUpdate(esId,"visited",{[this.props.user.id]:true});
    */
  }

  applyFile = (e) => {
    console.log("applyFile:" , e.currentTarget.dataset.id);
    let esId = e.currentTarget.dataset.id;
    let esTitle = e.currentTarget.dataset.title;
    this.setState({
      applyModal: true,
      applyId: esId,
      applyTitle: esTitle,

    })
  }
  rejectFile = (e) => {
    console.log("rejectFile:" , e.currentTarget.dataset.id);
    let esId = e.currentTarget.dataset.id;
    let esTitle = e.currentTarget.dataset.title;
    this.setState({
      rejectModal: true,
      rejectId: esId,
      rejectTitle: esTitle,
    })
  }
  applyClose = async (e) => {
    this.setState({
      applyModal: false,
      applyId: null,
      applyTitle: null,
    });

    let esId = e.currentTarget.dataset.id;
    let collection;
    if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.maker) {
      // manufacturer
      collection = this.props.db.collection('items_stock').doc(this.props.userOrg.id).collection('buyers').doc(this.props.org.id).collection('items')
    } else if(this.props.org && this.props.org.maker && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/Purchase mode
      collection = this.props.db.collection('items_stock').doc(this.props.org.id).collection('buyers').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.buyer) {
      // purchasing user
      collection = this.props.db.collection('items_sell').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.buyer && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/sales mode
      collection = this.props.db.collection('items_sell').doc(this.props.org.id).collection('items')
    }

    if(esId && collection){

      let o = await this.props.db.collection('orders').doc(esId).get();
      let data = o.data();
      let cart = data.cart || {};

      await Promise.all(cart.items.map(async (item) => {
        let itemSell = await collection.doc(item.id).get();
        let itemSellData = itemSell.data();
        if (itemSellData && itemSellData['price']) {
          item.price = itemSellData['price'][0]['price'];
        }
      }));

      let cartRef = this.props.db.collection('carts').doc(data.fromUser)
          .collection('carts').doc(data.toOrganization);
      cartRef.set({
        [data.cartNo]: {},
      }, { merge: true });

      let deliveryAddress = {}
      if (cart.orderBasyo === '前回の納品先' || cart.orderBasyo === 'アドレス帳から選択する') {
        deliveryAddress = cart.deliveryAddress;
      }

      if (cart.orderBasyo === '納品先を直接入力する') {
        deliveryAddress = {
          propertyName: cart.orderPropertyName,
          postCode: cart.orderZipcode,
          address1: cart.orderAddr1,
          address2: cart.orderAddr2,
          officeName: cart.orderJigyosyo,
          personInCharge: cart.orderTanto,
          phoneNumber: cart.orderPhone
        }
      }

      if (cart.orderBasyo === '販売側からお助けカートで指定された納品先' &&
          Object.keys(cart.otasukeDeliveryAddress || {}).length > 0 &&
          cart.otasukeDeliveryAddress.postCode &&
          cart.otasukeDeliveryAddress.address1 &&
          cart.otasukeDeliveryAddress.address2 &&
          cart.otasukeDeliveryAddress.officeName &&
          cart.otasukeDeliveryAddress.personInCharge &&
          cart.otasukeDeliveryAddress.phoneNumber
      ) {
        deliveryAddress = cart.otasukeDeliveryAddress
      }

      let cartInfoRef = this.props.db.collection('carts_info').doc(data.fromUser)
          .collection('carts_info').doc(data.toOrganization);
      if (Object.keys(deliveryAddress).length > 0) {
        cartInfoRef.set({
          lastDeliveryAddress: deliveryAddress,
          update: firebase.firestore.Timestamp.fromDate(new Date())
        }, { merge: true })
      }

      let ordersRef = this.props.db.collection('orders').doc(esId);

      let username = this.props.user.last_name + ' '+ this.props.user.first_name
      await ordersRef.set({
        apply: {[this.props.user.id]: true},
        applied: true,
        newApply: true,
        cart,
        applyUserName: username,
        orderDate: firebase.firestore.Timestamp.fromDate(new Date()),
        latestUpdate: firebase.firestore.Timestamp.fromDate(new Date()),
        sortDate: firebase.firestore.Timestamp.fromDate(new Date()),
      }, { merge: true });

      this.props.loadBadge();

      this.clearFilter()

      if(this.props.user.authorizer){
        ordersRef.set({
          authorizer_visited: true,
        }, { merge: true });

        this.dbOrdersUpdate(esId,"authorizer_visited",true);
      }

      this.dbOrdersUpdate(esId,"applied",true);
      this.dbOrdersUpdate(esId, "newApply", true);
      this.dbOrdersUpdate(esId,"apply",{key:1});
      this.dbOrdersUpdate(esId,"applyUserName",username);
      this.dbOrdersUpdate(esId,"orderDate", firebase.firestore.Timestamp.fromDate(new Date()));
      this.dbOrdersUpdate(esId,"latestUpdate", firebase.firestore.Timestamp.fromDate(new Date()));
      this.dbOrdersUpdate(esId,"sortDate", firebase.firestore.Timestamp.fromDate(new Date()));

      // ordersRef
      // .get()
      // .then((doc) => {
      //   if(doc.exists) {
      //     let data = doc.data();
      //     // console.log(data)
      //     // console.log(data.cartNo);
      //     // console.log(data.fromUser);
      //     // console.log(data.toOrganization);
      //
      //     try {
      //       let cartRef = this.props.db.collection('carts').doc(data.fromUser)
      //           .collection('carts').doc(data.toOrganization);
      //
      //       let setWithMerge = cartRef.set({
      //         [data.cartNo]: {},
      //       }, { merge: true });
      //
      //       let cart = data.cart || {};
      //
      //       let deliveryAddress = {}
      //       if (cart.orderBasyo === '前回の納品先' || cart.orderBasyo === 'アドレス帳から選択する') {
      //         deliveryAddress = cart.deliveryAddress;
      //       }
      //
      //       if (cart.orderBasyo === '納品先を直接入力する') {
      //         deliveryAddress = {
      //           propertyName: cart.orderPropertyName,
      //           postCode: cart.orderZipcode,
      //           address1: cart.orderAddr1,
      //           address2: cart.orderAddr2,
      //           officeName: cart.orderJigyosyo,
      //           personInCharge: cart.orderTanto,
      //           phoneNumber: cart.orderPhone
      //         }
      //       }
      //
      //       if (cart.orderBasyo === '販売側からお助けカートで指定された納品先' &&
      //           Object.keys(cart.otasukeDeliveryAddress || {}).length > 0 &&
      //           cart.otasukeDeliveryAddress.postCode &&
      //           cart.otasukeDeliveryAddress.address1 &&
      //           cart.otasukeDeliveryAddress.address2 &&
      //           cart.otasukeDeliveryAddress.officeName &&
      //           cart.otasukeDeliveryAddress.personInCharge &&
      //           cart.otasukeDeliveryAddress.phoneNumber
      //       ) {
      //         deliveryAddress = cart.otasukeDeliveryAddress
      //       }
      //
      //       let cartInfoRef = this.props.db.collection('carts_info').doc(data.fromUser)
      //           .collection('carts_info').doc(data.toOrganization);
      //
      //       if (Object.keys(deliveryAddress).length > 0) {
      //         cartInfoRef.set({
      //           lastDeliveryAddress: deliveryAddress,
      //           update: firebase.firestore.Timestamp.fromDate(new Date())
      //         }, { merge: true })
      //       }
      //
      //     } catch (e) {
      //       console.log(e)
      //
      //     }
      //
      //     let t = this;
      //     let username = this.props.user.last_name + ' '+ this.props.user.first_name
      //     ordersRef.set({
      //       apply: {[this.props.user.id]: true},
      //       applied: true,
      //       newApply: true,
      //       applyUserName: username,
      //       orderDate: firebase.firestore.Timestamp.fromDate(new Date()),
      //       latestUpdate: firebase.firestore.Timestamp.fromDate(new Date()),
      //       sortDate: firebase.firestore.Timestamp.fromDate(new Date()),
      //     }, { merge: true })
      //         .then(function () {
      //           t.clearFilter()
      //         })
      //         .catch(function (error) {
      //           console.error("Error set document: ", error);
      //         });
      //
      //     if(this.props.user.authorizer){
      //       ordersRef.set({
      //         authorizer_visited: true,
      //       }, { merge: true });
      //
      //       this.dbOrdersUpdate(esId,"authorizer_visited",true);
      //     }
      //
      //     this.dbOrdersUpdate(esId,"applied",true);
      //     this.dbOrdersUpdate(esId, "newApply", true);
      //     this.dbOrdersUpdate(esId,"apply",{key:1});
      //     this.dbOrdersUpdate(esId,"applyUserName",username);
      //     this.dbOrdersUpdate(esId,"orderDate", firebase.firestore.Timestamp.fromDate(new Date()));
      //     this.dbOrdersUpdate(esId,"latestUpdate", firebase.firestore.Timestamp.fromDate(new Date()));
      //     this.dbOrdersUpdate(esId,"sortDate", firebase.firestore.Timestamp.fromDate(new Date()));
      //   }
      // })


    }


  }
  rejectClose = async (e) => {
    this.setState({
      rejectModal: false,
      rejectId: null,
      rejectTitle: null,
    });

    let esId = e.currentTarget.dataset.id;
    let collection;
    if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.maker) {
      // manufacturer
      collection = this.props.db.collection('items_stock').doc(this.props.userOrg.id).collection('buyers').doc(this.props.org.id).collection('items')
    } else if(this.props.org && this.props.org.maker && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/Purchase mode
      collection = this.props.db.collection('items_stock').doc(this.props.org.id).collection('buyers').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.buyer) {
      // purchasing user
      collection = this.props.db.collection('items_sell').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.buyer && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/sales mode
      collection = this.props.db.collection('items_sell').doc(this.props.org.id).collection('items')
    }

    if(esId && collection){
      let ordersRef = this.props.db.collection('orders').doc(esId);

      let o = await ordersRef.get();
      let data = o.data();
      let cart = data.cart || {};

      await Promise.all(cart.items.map(async (item) => {
        let itemSell = await collection.doc(item.id).get();
        let itemSellData = itemSell.data();
        if (itemSellData && itemSellData['price']) {
          item.price = itemSellData['price'][0]['price'];
        }
      }));

      let cartRef = this.props.db.collection('carts').doc(data.fromUser)
          .collection('carts').doc(data.toOrganization);

      let setWithMerge = cartRef.set({
        [data.cartNo]: {
          requested: false,
          rejected: true,
        },
      }, { merge: true });

      let username = this.props.user.last_name + ' '+ this.props.user.first_name
      let t = this;
      await ordersRef.set({
        reject: {[this.props.user.id]: true},
        rejectUserName: username,
        newReject: true,
        cart,
        rejectDate: firebase.firestore.Timestamp.fromDate(new Date()),
        latestUpdate: firebase.firestore.Timestamp.fromDate(new Date()),
        sortDate: firebase.firestore.Timestamp.fromDate(new Date()),
      }, { merge: true })

      this.props.loadBadge();

      t.clearFilter()

      this.dbOrdersUpdate(esId,"reject",{key:1});
      this.dbOrdersUpdate(esId,"newReject",true);
      this.dbOrdersUpdate(esId,"rejectUserName",username);
      this.dbOrdersUpdate(esId,"rejectDate",firebase.firestore.Timestamp.fromDate(new Date()),);
      this.dbOrdersUpdate(esId,"latestUpdate", firebase.firestore.Timestamp.fromDate(new Date()));
      this.dbOrdersUpdate(esId,"sortDate", firebase.firestore.Timestamp.fromDate(new Date()));


      // ordersRef
      // .get()
      // .then((doc) => {
      //   if(doc.exists) {
      //     let data = doc.data();
      //
      //     let cartRef = this.props.db.collection('carts').doc(data.fromUser)
      //       .collection('carts').doc(data.toOrganization);
      //
      //     let setWithMerge = cartRef.set({
      //       [data.cartNo]: {
      //         requested: false,
      //         rejected: true,
      //       },
      //     }, { merge: true });
      //   }
      // })

    }

  }

  waitFile = (e) => {
    console.log("waitFile:" , e.currentTarget.dataset.id);
    let esId = e.currentTarget.dataset.id;
    let esTitle = e.currentTarget.dataset.title;
    this.setState({
      waitModal: true,
      waitId: esId,
      waitTitle: esTitle,
    })
  }
  waitClose = (e) => {
    this.setState({
      waitModal: false,
      waitId: null,
      waitTitle: null,
    })

    let esId = e.currentTarget.dataset.id;
    if(esId){
      let ordersRef = this.props.db.collection('orders').doc(esId);
      ordersRef.set({
        wait: {[this.props.user.id]: true},
      }, { merge: true });
      this.dbOrdersUpdate(esId,"wait",{key:1});
    }
  }

  dbOrdersUpdate = (id,target,value) => {
    let dbOrdersUpdated = this.state.dbOrders.map((es)=>{
      let orderID = es.id;
      if (es.objectID) {
        orderID = es.objectID;
      }
      if(orderID === id){
        if(target === 'visited') {
          let visited = es.visited;
          if(visited){
            es[target] = Object.assign(visited, value);
          } else {
            es[target] = value;
          }
        }else{
          es[target] = value;
        }
      }
      return es;
    })
    this.setState({
      dbOrders: dbOrdersUpdated,
    })
  }

  orderIsSelected = (docId) => {
    let filtered = this.state.selectedOrderList.filter(item => {
      if (item.objectID) {
        return item.objectID === docId
      } else {
        return item.id === docId
      }

    });
    return filtered.length > 0
  };

  orderSelectAll = (selected) => {
    let selectedOrderList = [];
    if (selected === true) {
      this.state.dbOrders.forEach((order,index) => {
        // if (this.state.userType === "seller" && order.readSeller === false || this.state.userType === "buyer" && order.readBuyer === false || this.state.userType === "sellerBuyer" && order.readBuyer === false || this.state.userType === "maker" && order.readSeller === false) {
          selectedOrderList.push(order);
        // }
      });

      this.setState({
        selectedOrderList: selectedOrderList
      }, () => {
        // console.log(this.state.selectedOrderList)
      })
    } else {
      this.setState({
        selectedOrderList: selectedOrderList
      });
    }

  };

  orderSelect = (selected, order) => {
    let selectedOrderList;
    if (selected) {
      selectedOrderList = [...this.state.selectedOrderList, order];
    } else {
      selectedOrderList = this.state.selectedOrderList.filter( sOrder => {
        if (sOrder.objectID) {
          return sOrder.objectID !== order.objectID
        } else {
          return sOrder.id !== order.id
        }

      });
    }

    this.setState({
      selectedOrderList: selectedOrderList
    })
  }

  orderRead = () => {
    let batch = this.props.db.batch();

    this.state.selectedOrderList.forEach((doc) => {
      console.log(doc.id);
      let updateData = {};
      let orderID = doc.id;
      if (doc.objectID) {
        orderID = doc.objectID;
      }

      if (this.state.userType === "seller" && doc.readSeller === false || this.state.userType === "maker" && doc.readSeller === false) {
        updateData.readSeller = true;
        this.dbOrdersUpdate(orderID,"readSeller",true);
      }

      if (this.state.userType === "buyer" && doc.readBuyer === false || this.state.userType === "sellerBuyer" && doc.readBuyer === false) {
        updateData.readBuyer = true;
        this.props.readOrder(this.state.selectedOrderList.length);
        this.dbOrdersUpdate(orderID,"readBuyer",true);
      }

      const docRef = this.props.db.collection("orders").doc(orderID);
      if (Object.keys(updateData).length > 0)
        batch.update(docRef, updateData);
    })

    batch.commit().then(() => {
      console.log(`updated all documents inside "orders"`);
      this.setState({selectedOrderList : []})
    });
  }

  handleSearchwordAChange = (e) => {
    this.setState({
      searchwordA: e.currentTarget.value,
    });
  }

  handleSearchwordAKeyDown = (e) => {
    let val = this.state.searchwordA;
    if (e.key === 'Enter') {
      this.setState({
        selectedOrderList : [],
        dbOrders: [],
        last: null,
        notMore: false,
        algoriaPage: 1,
      }, ()=>{
        if (val) {
          this.getOrdersA(this.props, true, val);
        } else {
          this.getOrders(this.props, true)
        }

      });
    }
  }

  checkShipping = (order) => {
    // console.log('checkShipping', order);
    let isShipped = order.isShipped;
    // let cart = order.cart;
    // let items = cart.items;
    // let isShipped = true;
    //
    // for (let i=0; i<items.length; i++) {
    //   let item = items[i];
    //   let okurijyoNo = item.okurijyoNo;
    //
    //   if (okurijyoNo) {
    //     let shippingDate = okurijyoNo.slice(-1)[0].shippingDate;
    //     let value = okurijyoNo.slice(-1)[0].value;
    //
    //     if (!shippingDate || !value) {
    //       isShipped = false;
    //       break;
    //     }
    //   } else if (item.divided) {
    //
    //   } else {
    //     isShipped = false;
    //     break;
    //   }
    //
    //   if (!isShipped) {
    //     break;
    //   }
    // }

    if (isShipped) {
      return <div style={{width: 15, height: 15, borderRadius: 10, backgroundColor: '#19A22B'}}/>
    } else {
      return <div style={{width: 15, height: 15, borderRadius: 10, backgroundColor: '#FF3547'}}/>
    }

  }

  selectedDownloadPDF = async () => {
    const jszip = new JSZip();
    this.setState({loadingPDF: true});

    function n(n){
      return n > 9 ? "" + n: "0" + n;
    }

    let promises = this.state.selectedOrderList.map(async (order, index) => {
      let pdf = await this.downloadPDF(order);
      jszip.file(pdf.name, pdf.blob);
    }).reduce((acc, curr) => acc.then(() => curr), Promise.resolve());
    await promises;
    // console.log('done');

    jszip.generateAsync({type:'blob'}).then(function(content) {
      let date = new Date();
      let nDate = date.getFullYear() + ''
          + n(date.getMonth() + 1) + ''
          + n(date.getDate()) + ''
          + n(date.getHours()) + ''
          + n(date.getMinutes()) + ''
          + n(date.getSeconds());
      saveAs(content, `orders-${nDate}.zip`);
    });
    this.setState({loadingPDF: false});
  }

  downloadPDF = async (order) => {
    function n(n){
      return n > 9 ? "" + n: "0" + n;
    }

    let kaigawa = false;
    let urigawa = false;
    if ( (this.props.userOrg && this.props.userOrg.buyer && this.props.org && this.props.org.reseller)
        || (this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.maker)
    ){
      kaigawa = true;
    }
    if ( (this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.buyer)
        || (this.props.userOrg && this.props.userOrg.maker && this.props.org && this.props.org.reseller)
    ) {
      urigawa = true;
    }

    let sellerOrg = {};
    let buyerOrg = {};

    if (kaigawa) {
      sellerOrg = this.props.org;
      buyerOrg = this.props.userOrg;
    }

    if (urigawa) {
      sellerOrg = this.props.userOrg;
      buyerOrg = this.props.org;
    }

    let data = order;
    let cart = order.cart;
    let items = cart.items || [];
    let fileNamePDF = '';
    let deliveryAddress = {};
    let applyId = '';
    let mainInstallmentStatus = '分納手続中';

    if (data.apply) {
      applyId = Object.keys(data.apply)[0];
    }

    let orderNoukiDate = '';
    if (cart.orderNouki == '日付指定') {
      orderNoukiDate = cart.orderNoukiDate ? cart.orderNoukiDate.toDate().getFullYear() + '/'
          + n(cart.orderNoukiDate.toDate().getMonth()+1) + '/'
          + n(cart.orderNoukiDate.toDate().getDate()) + '' : '';
    }

    let toUserData = await firebase.functions().httpsCallable('getUserEmail')({uid: data.toUser})

    let date;
    if (data.orderDate) {
      date = data.orderDate;
    } else {
      date = data.created;
    }

    if (this.state.algolia) {
      date = new Date(date._seconds * 1000 + date._nanoseconds/1000000);
    } else {
      date = date.toDate();
    }

    // console.log('date', date, this.state.algolia)

    let orderDate = date.getFullYear() + '年'
        + n(date.getMonth() + 1) + '月'
        + n(date.getDate()) + '日 '
        + n(date.getHours()) + ':'
        + n(date.getMinutes());

    let nDate = date.getFullYear() + ''
        + n(date.getMonth() + 1) + ''
        + n(date.getDate()) + ''
        + n(date.getHours()) + ''
        + n(date.getMinutes()) + ''
        + n(date.getSeconds());

    let divideDate;
    if (data.divideDate) {
      divideDate = data.divideDate;
      if (this.state.algolia) {
        divideDate = new Date(divideDate._seconds * 1000 + divideDate._nanoseconds/1000000);
      } else {
        divideDate = divideDate.toDate();
      }

      divideDate = divideDate.getFullYear() + ''
          + n(divideDate.getMonth() + 1) + ''
          + n(divideDate.getDate()) + ''
          + n(divideDate.getHours()) + ''
          + n(divideDate.getMinutes()) + ''
          + n(divideDate.getSeconds());
    }

    let no = nDate+'-'+data.id;

    //divided item-g xasax
    items = items.filter(v => v.divided !== true);

    let totalAmount = 0;
    let totalTax = 0;
    let taxes = {};
    let totals = {};
    let subDivideCount = 0;
    let subDivideConfirmCount  = 0;
    cart.items.forEach((item) => {
      if (item.subDivided) {
        subDivideCount += 1;
        if (item.divideConfirm === true) {
          subDivideConfirmCount += 1;
        }
      }
      if (!item.subDivided) {
        if (!item.deletedItem && !item.tax) {
          item.tax = 0.1;
        }
        totalAmount += (item.price*item.count);
        totalTax += parseInt(item.count * item.price * item.tax, 10);

        if (taxes[item.tax]) {
          taxes[item.tax] += (item.count * item.price * item.tax);
          totals[item.tax] += (item.count * item.price);
        } else {
          taxes[item.tax] = (item.count * item.price * item.tax);
          totals[item.tax] = (item.count * item.price);
        }
      }
    });

    if (subDivideCount === subDivideConfirmCount) {
      mainInstallmentStatus = '分納完了済';
    } else {
      mainInstallmentStatus = '分納手続中';
    }

    switch (cart.orderBasyo) {
      case '登録住所':
        deliveryAddress = {
          postCode: this.props.userOrg.zipcode,
          address1: this.props.userOrg.address,
          address2: '',
          officeName: this.props.userOrg.display_name,
          personInCharge: data['fromUserName'],
          phoneNumber: this.props.userOrg.TEL,
          propertyName: ""
        };
        break;
      case '登録住所以外':
        deliveryAddress = {
          postCode: cart.orderZipcode,
          address1: cart.orderAddr1,
          address2: cart.orderAddr2,
          officeName: cart.orderJigyosyo,
          personInCharge: cart.orderTanto,
          phoneNumber: cart.orderPhone,
          propertyName: ""
        }
        break;
      case '前回の納品先':
        deliveryAddress = cart.deliveryAddress;
        break;
      case 'アドレス帳から選択する':
        deliveryAddress = cart.deliveryAddress;
        break;
      case '販売側からお助けカートで指定された納品先':
        deliveryAddress = cart.otasukeDeliveryAddress;
        break;
      case '納品先を直接入力する':
        deliveryAddress = {
          postCode: cart.orderZipcode,
          address1: cart.orderAddr1,
          address2: cart.orderAddr2,
          officeName: cart.orderJigyosyo,
          personInCharge: cart.orderTanto,
          phoneNumber: cart.orderPhone,
          propertyName: cart.orderPropertyName
        }
        break;

    }

    let orderStatus='';

    if(order['apply'] && Object.keys(order['apply']).length > 0) {
      orderStatus = 'apply';
    } else if(order['reject'] && Object.keys(order['reject']).length > 0) {
      orderStatus = 'reject';
    } else if(order['wait'] && Object.keys(order['wait']).length > 0) {
      orderStatus = 'wait';
    }

    fileNamePDF = 'ORD-'+nDate+'-'+data.id;

    let dataPDF = {
      sellerZipcode: sellerOrg.zipcode,
      sellerAddress: sellerOrg.address,
      sellerFAX: sellerOrg.FAX,
      sellerTEL: sellerOrg.TEL,
      sellerMAIL: toUserData.data,
      sellerOrganizationsCompanyName: sellerOrg.company_name,
      sellerOrganizationsName: sellerOrg.name,

      buyerZipcode: buyerOrg.zipcode,
      buyerAddress: buyerOrg.address,
      buyerFAX: buyerOrg.FAX,
      buyerTEL: buyerOrg.TEL,
      buyerOrganizationsCompanyName: buyerOrg.company_name,
      buyerOrganizationsName: buyerOrg.name,

      toUserName: data.toUserName,
      fromUserName: data.fromUserName,
      applyUserName: data.applyUserName,
      applyId: applyId,

      orderNo: cart.orderNo,
      deliveryAddress: deliveryAddress,
      orderDate: orderDate,
      orderNouki: cart.orderNouki,
      orderNoukiDay: cart.orderNoukiDay,
      orderNoukiDate: orderNoukiDate,
      orderBikou: cart.orderBikou,
      orderStatus: orderStatus,
      mainInstallmentStatus: mainInstallmentStatus,
      no: no,
      items: items,
      totalAmount: totalAmount,
      totalTax: totalTax,
      taxes: taxes,
      totals: totals,
      divideDate: divideDate
    }
    return this.pdfGenerator(fileNamePDF, dataPDF);
  }

  addFooters = (doc, data) => {
    const pageCount = doc.internal.getNumberOfPages()

    doc.setFont('Meiryo', 'normal')
    doc.setFontSize(8)
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text('Page ' + String(i) + '/' + String(pageCount), 15, 15);

      if (i > 1) {
        doc.text(`発注日 ${data.orderDate}\nNo.${data.no}`, 130, 15);
      }
    }
  }

  pdfGenerator = (fileName, data) => {
    let doc = new jsPDF('p', 'mm', 'a4', true);
    let top = 20;
    doc.addFileToVFS("MeiryoRegular.ttf", MeiryoRegular);
    doc.addFileToVFS("MeiryoBold.ttf", MeiryoBold);
    doc.addFont("MeiryoRegular.ttf", "Meiryo", "normal");
    doc.addFont("MeiryoBold.ttf", "Meiryo", "bold");
    doc.setFont("Meiryo", 'normal');
    doc.setFontSize(8);
    // doc.setPage(1)
    // doc.text(`株式会社昭和商会\nアメノミクス２　１ｋｇ`, 5, 5);
    // doc.text('Page 1/1', 15, 20);
    doc.setFontSize(25);
    doc.text('発注書', 90, top);
    doc.setFontSize(8);

    let topRightY = 15;
    if (data.divideDate) {
      doc.text(`（分納版 - ${data.divideDate})`, 195, topRightY, {align: 'right'});
      doc.text(`${data.mainInstallmentStatus}`, 179.5, topRightY+4);
      doc.roundedRect(178, topRightY+1.4, 17, 3.2, 0.5, 0.5);

      topRightY += 8.3;
    }

    if (data.orderStatus === 'wait') {
      doc.text(`上長承認依頼中（未発注）`, 161, topRightY);
      doc.roundedRect(159.7,  topRightY-2.7, 35.3, 3.2, 0.5, 0.5);
    }

    if (data.orderStatus === 'reject') {
      doc.text(`却下（未発注）`, 175.5, topRightY);
      doc.roundedRect(174, topRightY-2.7, 21, 3.2, 0.5, 0.5);
    }

    doc.text(`〒${data.sellerZipcode}`, 15, top+20);
    doc.text(`${data.sellerAddress}`, 15, top+25);

    doc.text(`発注日 ${data.orderDate}`, 125, top+20);
    doc.text(`No.${data.no}`, 125, top+25);

    doc.setFont('Meiryo', 'bold');
    doc.text(`${data.sellerOrganizationsCompanyName} ${data.sellerOrganizationsName}`, 17, top+30);
    doc.text('御中', 110, top+30);
    doc.line(15, top+31, 110, top+31);

    doc.setFont('Meiryo', 'normal');
    doc.text(`${data.toUserName} 様`, 15, top+37);
    doc.text(`〒${data.buyerZipcode}`, 125, top+37);
    doc.text('埼玉県さいたま市浦和区', 125, top+42);
    doc.text(`${data.buyerOrganizationsCompanyName} ${data.buyerOrganizationsName}`, 125, top+50);
    doc.text(`TEL: ${data.buyerTEL}`, 125, top+55);
    doc.text(`FAX: ${data.buyerFAX}`, 170, top+55);
    doc.setFontSize(6);
    doc.text(`TEL: ${data.sellerTEL}`, 15, top+40);
    doc.text(`FAX: ${data.sellerFAX}`, 40, top+40);
    doc.text(`Mail: ${data.sellerMAIL}`, 15, top+43);
    doc.setFontSize(8);
    doc.text('以下の通り発注いたします。', 15, top+53);

    doc.text('〔本書に関するお問い合わせ〕', 125, top+63);
    let fromUserName = doc.splitTextToSize(`発注者 ${data.buyerOrganizationsCompanyName} ${data.buyerOrganizationsName} ${data.fromUserName}`, 70);
    doc.text(fromUserName, 125, top+68);

    if (data.orderStatus !== 'reject') {
      let applyUserName = doc.splitTextToSize(`承認者 ${data.buyerOrganizationsCompanyName} ${data.buyerOrganizationsName} ${data.applyUserName || data.applyId}`, 70)
      doc.text(applyUserName, 125, top+78);
    }

    top = top+63

    doc.text('発注番号:', 15, top);
    let text = `${data.orderNo}`.trim();
    let da = doc.splitTextToSize(text, 75, {fontSize: 8, lineHeightFactor: 1.15});
    let amountOfLines = da.length;
    doc.text(da, 40, top);
    if (amountOfLines > 1) {
      top += doc.getTextDimensions(da)['h']-2;
    } else {
      top += 1
    }
    doc.line(40, top, 115, top);

    top += 4;
    doc.text('納品場所:', 15, top);
    let text1 = `〒${data.deliveryAddress.postCode}`.trim();
    let da1 = doc.splitTextToSize(text1, 75, {fontSize: 8, lineHeightFactor: 1.15});
    let amountOfLines1 = da1.length;
    doc.text(da1, 40, top);
    if (amountOfLines1 > 1) {
      top += doc.getTextDimensions(da1)['h']-2;
    } else {
      top += 3;
    }

    let text2 = `${data.deliveryAddress.propertyName} ${data.deliveryAddress.address1} ${data.deliveryAddress.address2} ${data.deliveryAddress.officeName}`.trim();
    let da2 = doc.splitTextToSize(text2, 75, {fontSize: 8, lineHeightFactor: 1.15});
    let amountOfLines2 = da2.length;
    doc.text(da2, 40, top);
    if (amountOfLines2 > 1) {
      top += doc.getTextDimensions(da2)['h']-2;
    } else {
      top += 1
    }
    doc.line(40, top, 115, top);

    top += 4;
    doc.text('受取担当者:', 15, top);
    let text3 = `${data.deliveryAddress.personInCharge} ${data.deliveryAddress.personInCharge && '様'}`.trim();
    let da3 = doc.splitTextToSize(text3, 75, {fontSize: 8, lineHeightFactor: 1.15});
    let amountOfLines3 = da3.length;
    doc.text(da3, 40, top);
    if (amountOfLines3 > 1) {
      top += doc.getTextDimensions(da3)['h']-2;
    } else {
      top += 1
    }
    doc.line(40, top, 115, top);

    top += 4;
    doc.text('電話番号:', 15, top);
    let text4 = `${data.deliveryAddress.phoneNumber}`.trim();
    let da4 = doc.splitTextToSize(text4, 75, {fontSize: 8, lineHeightFactor: 1.15});
    let amountOfLines4 = da4.length;
    doc.text(da4, 40, top);
    if (amountOfLines4 > 1) {
      top += doc.getTextDimensions(da4)['h']-2;
    } else {
      top += 1
    }
    doc.line(40, top, 115, top);

    top += 4;
    doc.text('希望納期:', 15, top);
    let orderNouki = data['orderNouki'] === '最短' ? '最短' : data['orderNouki'] === '希望' ? `${data['orderNoukiDay']}日` : `${data['orderNoukiDate']}`
    let da5 = doc.splitTextToSize(`${orderNouki}`, 75, {fontSize: 8, lineHeightFactor: 1.15});
    let amountOfLines5 = da5.length;
    doc.text(da5, 40, top);
    if (amountOfLines5 > 1) {
      top += doc.getTextDimensions(da5)['h']-2;
    } else {
      top += 1
    }
    doc.line(40, top, 115, top);

    autoTable(doc, {
      startY: top+8,
      head: [['税抜合計金額', '消費税額', '今回発注額']],
      body: [
        [
          `${data.totalAmount.toLocaleString()}円`,
          `${parseInt((Object.values(data.taxes).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0)),10).toLocaleString()}円`,
          `${(data.totalAmount + parseInt((Object.values(data.taxes).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0)),10)).toLocaleString()}円`],
      ],
      headStyles: {
        fillColor: [189, 190, 191],
        textColor: [0],
        halign: 'center',
        font: 'Meiryo',
        fontSize: 8,
        lineWidth: .1,
        lineColor: 'black'
      },
      columnStyles: {
        0: { cellWidth: 33, halign: 'right' },
        1: { cellWidth: 34, halign: 'right' },
        2: { cellWidth: 34, halign: 'right' }
      },
      bodyStyles: {
        font: 'Meiryo',
        fontSize: 8,
        color: 0,
        lineWidth: .1,
        lineColor: 'black'
      },
      theme: 'plain'
    });

    let posY = doc.lastAutoTable.finalY;
    // console.log('posY', posY);

    autoTable(doc, {
      startY: posY+8,
      head: [['課税対象額(10%)', '消費税(10%)', '課税対象額(8%)', '消費税(8%)']],
      body: [
        [
          `${(data.totals['0.1'] || 0).toLocaleString()}円`,
          `${parseInt((data.taxes['0.1'] || 0), 10).toLocaleString()}円`,
          `${(data.totals['0.08'] || 0).toLocaleString()}円`,
          `${parseInt((data.taxes['0.08'] || 0), 10).toLocaleString()}円`
        ],
      ],
      headStyles: {
        fillColor: [189, 190, 191],
        textColor: [0],
        halign: 'center',
        font: 'Meiryo',
        fontSize: 8,
        lineWidth: .1,
        lineColor: 'black'
      },
      columnStyles: {
        0: { cellWidth: 33, halign: 'right' },
        1: { cellWidth: 34, halign: 'right' },
        2: { cellWidth: 34, halign: 'right' },
        3: { cellWidth: 34, halign: 'right' }
      },
      bodyStyles: {
        font: 'Meiryo',
        fontSize: 8,
        color: 0,
        lineWidth: .1,
        lineColor: 'black'
      },
      theme: 'plain'
    });

    posY = doc.lastAutoTable.finalY;

    autoTable(doc, {
      startY: posY+8,
      head: [['備考', 'orderBikou']],
      showHead: false,
      body: [
        ['備考', `${data['orderBikou'] ? data['orderBikou'] : ''}`],
      ],
      headStyles: {
        fillColor: [189, 190, 191],
        textColor: [0],
        halign: 'center',
        font: 'Meiryo',
        fontSize: 8,
        lineWidth: .1,
        lineColor: 'black'
      },
      columnStyles: {
        0: { cellWidth: 15, minCellHeight: 20, halign: 'center', valign: 'middle', fillColor: [189, 190, 191] },
        1: { cellWidth: 'auto', halign: 'left', valign: 'middle' },
      },
      bodyStyles: {
        font: 'Meiryo',
        fontSize: 8,
        color: 0,
        lineWidth: .1,
        lineColor: 'black'
      },
      theme: 'plain'
    });

    posY = doc.lastAutoTable.finalY;

    const substringFullWidth = (str, limit) => {
      var l = str.length;
      var c = '';
      var res = '';
      var length = 0;
      for (var i = 0; i < l; i++) {
        c = str.charCodeAt(i);
        if (0x0000 <= c && c <= 0x0019) {
          // length 0
          // do nothing
        } else if (0x0020 <= c && c <= 0x1FFF) {
          // length 1
          length = length + 1;
        } else if (0x2000 <= c && c <= 0xFF60) {
          // length 2
          length = length + 2;
        } else if (0xFF61 <= c && c <= 0xFF9F) {
          // length 1
          length = length + 1;
        } else if (0xFFA0 <= c) {
          // length 2
          length = length + 2;
        }
        if (length <= limit) {
          res += str.charAt(i);
        }
        else {
          break;
        }
      }
      return res;
    }

    let no = 0;
    let subNum = 0;
    let subId = '';
    let itemDataBody = data.items.map((item, index) => {
      if (subId !== item.id) {
        subId = item.id;
        no += 1;
        if (item.subDivided) {
          subNum = 1;
        } else {
          subNum = 0;
        }
      } else {
        if (item.subDivided) {
          subNum += 1;
        } else {
          subNum = 0;
        }
      }

      let confirmStatus = '';
      if (item.subDivided) {
        // confirmStatus = <span style={{...styles.installmentStatus, fontSize: 11, marginRight: 0}}>分納手続中</span>;
        confirmStatus = `分納手続中`;
        if (item.divideConfirm) {
          // confirmStatus = <span style={{...styles.installmentStatus, fontSize: 11, marginRight: 0}}>分納</span>
          confirmStatus = `分納`;
        }
      }

      // console.log('item.name', item.maker, item.name, substringFullWidth(item.name, 180))

      return [
        `${no}${subNum > 0 ? `\n(${subNum})` : ''}`,
        `${item.code ? item.code : ''}${item.subDivided ? `\n${confirmStatus}` : ''}`,
        `${item.maker}\n${substringFullWidth(item.name,156)}`,
        `${item.price.toLocaleString()}\n${item.count.toLocaleString()}`,
        `${(item.count * item.price).toLocaleString()}\n${item.tax*100}%`,
        // `${item.description ? substringFullWidth(item.description,87).trim() : ''}`
        ``
      ]
    });

    autoTable(doc, {
      startY: posY+8,
      margin: {top: 20},
      rowPageBreak: 'avoid',
      head: [['No.', '商品コード', 'メーカー名\n商品名', '単価(円)\n数量', '合計金額(円)\n税率', '備考']],
      body: itemDataBody,
      headStyles: {
        fillColor: [189, 190, 191],
        textColor: [0],
        halign: 'center',
        font: 'Meiryo',
        fontSize: 8,
        lineWidth: .1,
        lineColor: 'black'
      },
      columnStyles: {
        0: { cellWidth: 10, halign: 'center', valign: 'middle' },
        1: { cellWidth: 20, halign: 'left', valign: 'middle' },
        2: { cellWidth: 'auto', halign: 'left', valign: 'middle' },
        3: { cellWidth: 15, halign: 'right', valign: 'middle' },
        4: { cellWidth: 25, halign: 'right', valign: 'middle' },
        5: { cellWidth: 35, halign: 'left', valign: 'middle', minCellHeight: 15 }
      },
      bodyStyles: {
        font: 'Meiryo',
        fontSize: 8,
        color: 0,
        lineWidth: .1,
        lineColor: 'black'
      },
      theme: 'plain',
      didDrawCell: (data) => {
        if (data.section === 'body' && data.column.index === 1) {
          if ((data.cell.text[1] === '分納手続中' || data.cell.text[1] === '分納') || (data.cell.text[2] === '分納手続中' || data.cell.text[2] === '分納')) {
            let w = 16.6;
            if (data.cell.text[1] === '分納' || data.cell.text[2] === '分納') {
              w = 8.5;
            }
            doc.roundedRect(data.cell.x+0.5, data.cell.y+data.cell.contentHeight+(data.cell.height-data.cell.contentHeight)/2-5.3, w, 3.2, 0.5, 0.5);
          }
        }
      },
      willDrawCell: (d) => {
        if (d.section === 'body' && d.column.index === 5) {
          let desc = data.items[d.row.index]['description'] || '';
          let a =  doc.splitTextToSize(desc, 32);
          d.cell.text = a.slice(0,4);
        }
      }
    });

    this.addFooters(doc, data);

    return {name: fileName+'.pdf', blob: doc.output('blob')};
  }

  render() {
    if (!this.props.org) {
      return (
        <>
          取引先を選択してください。
        </>
      )
    }

    let kaigawa = false;
    let urigawa = false;
    if ( (this.props.userOrg && this.props.userOrg.buyer && this.props.org && this.props.org.reseller)
    || (this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.maker)
    ){
      kaigawa = true;
    }
    if ( (this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.buyer)
      || (this.props.userOrg && this.props.userOrg.maker && this.props.org && this.props.org.reseller)
    ){
      urigawa = true;
    }

    if(! kaigawa && ! urigawa){
      return (
        <>
        </>
      )
    }
    const orders = [];

    function n(n){
      return n > 9 ? "" + n: "0" + n;
    }

    // console.log(urigawa, kaigawa, this.state.dbOrders);

    this.state.dbOrders.forEach((order,index) => {
      // const date = order['created'].toDate().getFullYear() + '/'
      //   + (order['created'].toDate().getMonth()+1) + '/'
      //   + order['created'].toDate().getDate() + '';

      let date = '';
      let time = '';
      let dateFilter = 'latestUpdate';
      let orderNoukiDate = '';
      // console.log(urigawa, kaigawa, this.state.orderType)

      if (urigawa) {
        if (this.state.orderType === 2) {
          dateFilter = 'sellerConfirmDate';
        } else if (this.state.orderType === 3) {
          dateFilter = 'sortDate';
        } else if (!this.state.orderType) {
          dateFilter = 'sortDate';
        }
      } else {
        if (this.state.orderType === 2) {
          dateFilter = 'sortDate';
        } else if (!this.state.orderType) {
          dateFilter = 'sortDate';
        }
      }

      // if (this.state.orderType === 2) {
      //   if (urigawa) {
      //     dateFilter = 'sellerConfirmDate';
      //   } else {
      //     dateFilter = 'sortDate';
      //   }
      //
      // }
      // console.log(this.state.orderType, dateFilter, urigawa, kaigawa)

      if (this.state.algolia) {
        if(order[dateFilter]){
          let dDate = new Date(order[dateFilter]._seconds * 1000 + order[dateFilter]._nanoseconds/1000000);
          // console.log('dateFilter',dateFilter, order[dateFilter], dDate)
          date = dDate.getFullYear() + '/'
              + n(dDate.getMonth()+1) + '/'
              + n(dDate.getDate());
          time = n(dDate.getHours()) + ':'
              + n(dDate.getMinutes());
        }else {
          let dDate = new Date(order['created']._seconds * 1000 + order['created']._nanoseconds/1000000);
          date = dDate.getFullYear() + '/'
              + n(dDate.getMonth()+1) + '/'
              + n(dDate.getDate());
          time = n(dDate.getHours()) + ':'
              + n(dDate.getMinutes());
        }

        if (order['cart'].orderNouki === '日付指定' && order['cart'].orderNoukiDate) {
          let cDate = new Date(order['cart'].orderNoukiDate._seconds * 1000 + order['cart'].orderNoukiDate._nanoseconds/1000000);
          orderNoukiDate = cDate.getFullYear() + '/'
              + (cDate.getMonth()+1) + '/'
              + cDate.getDate() + '';
        }
      } else {
        // console.log('odf', order[dateFilter], dateFilter)
        if(order[dateFilter]){
          date = order[dateFilter].toDate().getFullYear() + '/'
              + n(order[dateFilter].toDate().getMonth()+1) + '/'
              + n(order[dateFilter].toDate().getDate());
          time = n(order[dateFilter].toDate().getHours()) + ':'
              + n(order[dateFilter].toDate().getMinutes());
        }else {
          date = order['created'].toDate().getFullYear() + '/'
              + n(order['created'].toDate().getMonth()+1) + '/'
              + n(order['created'].toDate().getDate());
          time = n(order['created'].toDate().getHours()) + ':'
              + n(order['created'].toDate().getMinutes());
        }

        if (order['cart'].orderNouki === '日付指定') {
          orderNoukiDate = order['cart'].orderNoukiDate ? order['cart'].orderNoukiDate.toDate().getFullYear() + '/'
              + (order['cart'].orderNoukiDate.toDate().getMonth()+1) + '/'
              + order['cart'].orderNoukiDate.toDate().getDate() + '' : '';
        }
      }

      let visited = '';
      let apply = '';
      let toVisited = '';
      let actionUser = '';
      let approveUser = '';

      let noukiSDate = '';
      let noukiEDate = '';
      if(order.nouki){
        // const noukiSDateRaw = order.nouki.slice(-1)[0].sdate ? order.nouki.slice(-1)[0].sdate.toDate() : '';
        // if (noukiSDateRaw) {
        //   noukiSDate = noukiSDateRaw.getFullYear() + '/' + (noukiSDateRaw.getMonth()+1) + '/' + noukiSDateRaw.getDate() + '';
        // }
        // const noukiEDateRaw = order.nouki.slice(-1)[0].edate ? order.nouki.slice(-1)[0].edate.toDate() : '';
        // if (noukiEDateRaw) {
        //   noukiEDate = noukiEDateRaw.getFullYear() + '/' + (noukiEDateRaw.getMonth()+1) + '/' + noukiEDateRaw.getDate() + '';
        // }
      }

      // console.log(urigawa, kaigawa, order)


      if ( urigawa ){
        // if(order['visited'] && order['visited'][this.props.user.id]) {
        //   approveUser = order['approveUser'];
        //   visited = <MDBChip className="text-nowrap disabled">確認済</MDBChip>;
        // } else {
        //   visited = <MDBChip bgColor="danger-color" text="white" className="text-nowrap disabled">未確認</MDBChip>;
        // }
        if(order['apply'] && Object.keys(order['apply']).length > 0) {
          actionUser = order['applyUserName'];
          apply = <MDBChip bgColor="info-color" text="white" className="text-nowrap disabled">受注済</MDBChip>;
        } else if(order['reject'] && Object.keys(order['reject']).length > 0) {
          actionUser = order['rejectUserName'];
          apply = <MDBChip bgColor=" pink lighten-2" text="white" className="text-nowrap disabled">却下</MDBChip>;
        } else if(order['wait'] && Object.keys(order['wait']).length > 0) {
          apply = <MDBChip bgColor="warning-color" text="white" className="text-nowrap disabled">未受注</MDBChip>;
        }

        if(order['approveUser']) {
          approveUser = order['approveUser'];
        }

        if(order['sellerConfirm'] === true) {
          // approveUser = order['approveUser']
          toVisited = <MDBChip className="text-nowrap disabled">受注確認済</MDBChip>;
        } else if (order['sellerConfirm'] === false) {
          toVisited = <MDBChip bgColor="danger-color" text="white" className="text-nowrap disabled">受注未確認</MDBChip>;
        }
      }

      if ( kaigawa ){
        if(order['authorizer_visited']) {
          visited = <MDBChip className="text-nowrap disabled">確認済</MDBChip>;
        } else {
          visited = <MDBChip bgColor="danger-color " text="white" className="text-nowrap disabled">未確認</MDBChip>;
        }

        if(order['visited'] && order['visited'][order['toUser']] || order['approveUser']) {
          approveUser = order['approveUser'];
          toVisited = <MDBChip className="text-nowrap disabled">確認済</MDBChip>;
        } else {
          toVisited = <MDBChip bgColor="danger-color" text="white" className="text-nowrap disabled">未確認</MDBChip>;
        }

        if(order['apply'] && Object.keys(order['apply']).length > 0) {
          actionUser = order['applyUserName'];
          apply = <MDBChip bgColor="info-color" text="white" className="text-nowrap disabled">発注済</MDBChip>;
        } else if(order['reject'] && Object.keys(order['reject']).length > 0) {
          actionUser = order['rejectUserName'];
          apply = <MDBChip bgColor=" pink lighten-2" text="white" className="text-nowrap disabled">却下</MDBChip>;
        } else if(order['wait'] && Object.keys(order['wait']).length > 0) {
          apply = <MDBChip bgColor="warning-color" text="white" className="text-nowrap disabled">承認待</MDBChip>;
          let d = [];
          let deletedDescription = '';

          order['cart'].items.forEach((item, index) => {
            if (item.deletedItem) {
              if (!d.includes(item.deletedText)) {
                d.push(item.deletedText)
                deletedDescription = <>{deletedDescription}<span style={{color: 'red'}}>{item.deletedText}</span><br /></>
              }
            }
          });
          if (order['cart'].deletedItem) {
            apply = <>{apply}<br /><span style={{color: 'red'}}>{deletedDescription}</span></>
          }
        }
      }

      // console.log(order['cart'])
      let title = order['cart'].items[0].name;
      let count = 0;
      let total = 0;
      let divided = false;
      let dividedText = '分納'
      order['cart'].items.forEach((item, index) => {
        count += item.count;

        if (!item.deletedItem && !item.subDivided) {
          total += item.count * item.price;
        }

        if (item.divided) {
          divided = true;
        }
      });

      if (kaigawa) {
        dividedText = '分納';
      }

      // console.log("userType",this.state.userType, order["readSeller"], order["readBuyer"]);

      let bold = this.state.userType === "seller" && order["readSeller"] === false ||
          this.state.userType === 'buyer' && order["readBuyer"] === false ||
          this.state.userType === 'maker' && order['readSeller'] === false ||
          this.state.userType === 'sellerBuyer' && order["readBuyer"] === false;
      // let disabled = bold ? '' : 'disabled';
      let disabled = '';
      let orderId = order.objectID ? order.objectID : order.id
      let checked = this.orderIsSelected(orderId);

      let cart = order.cart;
      let items = cart.items;

      let countDivided = 0;
      let countDividedConfirmed = 0;
      for (let i=0; i<items.length; i++) {
        if (items[i].subDivided) {
          countDivided++;
          if (items[i].divideConfirm === true) {
            countDividedConfirmed++;
          }
        }
      }

      // console.log('order items', items, countDivided, countDividedConfirmed)
      // console.log('order', orderId, order, order.readComment)

      let data = {
        'check':<div style={{width: 50}}> <MDBInput label=" " type="checkbox" checked={checked} disabled={disabled} id={"checkbox"+index} onChange={(e) => this.orderSelect(e.target.checked, order)} /> </div>,
        '番号':<>
          <span style={{overflowWrap: 'anywhere', fontWeight: bold ? 'bold' : ''}}>{order['cart'].orderNo}</span>
          {order['cart'].orderNo && <br/>}
          {/*{order.divideConfirm === true && (divided && <span className={'dividedStatus'}>{dividedText}</span>)}*/}
          {/*{order.divideConfirm === false && <span className={'dividedStatusProgress'}>分納手続中</span>}*/}
          {countDivided > 0 && <div className={(countDivided === countDividedConfirmed ? 'divideView' : 'divideNotConfirmed') + ' text-nowrap'}><span style={{marginRight: 10}}>{countDivided === countDividedConfirmed ? '分納' : '分納手続中'}</span><span className={'divideConfirmed'}>{countDividedConfirmed}</span>/<span className={'divideTotal'}>{countDivided}</span></div>}
          {order.readComment === false && <div><MDBIcon icon="comments" className="mr-1 commentIcon" /></div>}
          {order.readComment === true && <div><MDBIcon icon="comments" className="mr-1 commentIconGray" /></div>}
          {countDivided > 0 && (order.readComment !== false && order.readComment !== true) && <br/>}
          <span style={{overflowWrap: 'anywhere', fontWeight: bold ? 'bold' : ''}}>{order['id']}</span>
        </>,
        '発注日時':<span style={{fontWeight: bold ? 'bold' : ''}} className="text-nowrap">{date}<br/>{time}</span>,
        '受取者': <span className="text-nowrap">{order['toUserName']}<br/>{toVisited}<br/>{order['approveUser'] ? order['approveUser'] : ''}</span>,
        'タイトル': <div style={{fontWeight: bold ? 'bold' : ''}}>{title} 計<span style={{fontSize: 18, fontWeight: 'bold'}}>{count.toLocaleString()}</span>点</div>,
        '金額': <div style={{fontWeight: bold ? 'bold' : '', textAlign: 'right'}}>{total.toLocaleString()}円</div>,
        // '詳細': <Link target="_blank" to={"/order/" + orderId}><MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" data-id={orderId} onClick={this.openFile} >表示</MDBChip></Link>,
        '詳細': <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" data-id={orderId} onClick={() => this.detail(orderId, index)} >表示</MDBChip>,
        // '確認':visited,
        '受注':<span className="text-nowrap">{actionUser}{actionUser ? <br /> : ''}{apply}</span>,
        '希望納期': <div style={{fontWeight: bold ? 'bold' : ''}}>{order['cart'].orderNouki == '最短' ? '最短' :  order['cart'].orderNouki == '希望' ? order['cart'].orderNoukiDay + '日' : orderNoukiDate}</div>,
        //'納期回答': <div>{noukiSDate}{(noukiSDate || noukiEDate) && <>〜</> }{noukiEDate}</div>,
      };
      if(kaigawa){
        data['確認'] = visited
      }
      if ( kaigawa ){
        // console.log('kaigawa', kaigawa, order.cart)
        // let deleted
        data['操作'] = ''
        if(this.props.user.authorizer){
          if(order['apply'] && Object.keys(order['apply']).length > 0) {
          } else if(order['reject'] && Object.keys(order['reject']).length > 0) {
          } else {
            data['操作'] = (
              <div className="text-nowrap">
                {
                  order.cart.deletedItem ? <MDBChip className="text-nowrap disabled" style={{cursor: 'default'}} data-id={orderId} data-title={title + ' 計' + count.toLocaleString() + '点'}>承認</MDBChip>
                      :
                      <MDBChip bgColor="default-color" text="white" className="text-nowrap" data-id={orderId} data-title={title + ' 計' + count.toLocaleString() + '点'} onClick={this.applyFile}>承認</MDBChip>
                }
              <br />
              <MDBChip bgColor="warning-color-dark" text="white" className="text-nowrap" data-id={orderId} data-title={title + ' 計' + count.toLocaleString() + '点'} onClick={this.rejectFile}>却下</MDBChip>
              </div>
            )
          }
        }else{
          if( (order['wait'] && Object.keys(order['wait']).length > 0)
            || (order['apply'] && Object.keys(order['apply']).length > 0)
          ) {
          } else {
            if(order['toUser']==this.props.user.id){
              data['操作'] = <MDBChip bgColor="default-color" text="white" className="text-nowrap" data-id={orderId} data-title={order.title} onClick={this.waitFile}>承認依頼</MDBChip>
            }
          }
        }
      }

      data['処理'] = <div style={{display: 'flex', justifyContent: 'center'}}>
        {order['apply'] && Object.keys(order['apply']).length > 0 && this.checkShipping(order)}
      </div>;
      orders.push(data);

    });

    // console.log(urigawa, kaigawa)

    let dateLabel = '';
    // console.log(this.state.orderType)
    if (urigawa) {
      switch (this.state.orderType) {
        case 2: dateLabel = "受注確認日時"; break;
        case 3: dateLabel = "受注日時"; break;
        case 4: dateLabel = "更新日時"; break;
        case 6: dateLabel = "更新日時"; break;
        default: dateLabel = "受注日時"; break;
      }
    }

    if (kaigawa) {
      switch (this.state.orderType) {
        case 1: dateLabel = "承認依頼日時"; break;
        case 2: dateLabel = "発注日時"; break;
        case 3: dateLabel = "上長却下日時"; break;
        case 4: dateLabel = "更新日時"; break;
        case 6: dateLabel = "更新日時"; break;
        default: dateLabel = "発注ステータス\n" +
            "確定日時"; break;
      }
    }


    const data = {
      columns: [
        {
          label: <div style={{width: 50}}><MDBInput label=" " type="checkbox" id="checkboxAll" onChange={(e) => this.orderSelectAll(e.target.checked)} /></div>,
          field: 'check',
          sort: 'asc',
          width: 50
        },
        {
          label: <div><row>発注番号</row><br/><row>発注書ID</row></div>,
          field: '発注書番号',
          sort: 'asc',
          width: 80,
          minimal: 'sm'
        },
        {
          label: dateLabel,
          field: '発行日',
          sort: 'asc',
          width: 80,
          minimal: 'sm'
        },
        {
          label: <div><row>発注書受取者</row><br/><row>受注確認者</row></div>,
          field: '発注書受取者',
          sort: 'asc',
          width: 200,
          minimal: 'sm'
        },
        {
          label: '発注内容',
          field: '発注内容',
          sort: 'disabled',
          width: 370,
          minimal: 'sm'
        },
        {
          label: <div style={{textAlign: 'center'}}>金額</div>,
          field: '金額',
          sort: 'disabled',
          width: 370,
          minimal: 'sm'
        },
        {
          label: '詳細',
          field: '詳細',
          sort: 'disabled',
          width: 50,
          minimal: 'sm'
        },
        // {
        //   label: (kaigawa) ? '上長確認' : '自確認',
        //   field: '確認',
        //   sort: 'disabled',
        //   width: 50,
        //   minimal: 'sm'
        // },
        {
          label: (kaigawa ? '状態' : '受注'),
          field: '受注',
          sort: 'disabled',
          width: 50,
          minimal: 'sm'
        },
        {
          label: '希望納期',
          field: '希望納期',
          sort: 'disabled',
          width: 50,
          minimal: 'sm'
        },
        /*
        {
          label: '納期回答',
          field: '納期回答',
          sort: 'disabled',
          width: 50,
          minimal: 'sm'
        },
        */
      ],
      rows: orders
    }

    if(kaigawa) {
      data.columns.push({
        label: '上長確認',
        field: '確認',
        sort: 'disabled',
        width: 50,
        minimal: 'sm'
      });

      data.columns.push({
        label: '操作',
        field: '操作',
        sort: 'disabled',
        width: 50,
        minimal: 'sm'
      });

      data.columns.push({
        label: <div style={{width: 100, display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
          <row style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><div style={{width: 15, height: 15, borderRadius: 10, backgroundColor: '#19A22B', marginRight: 5}}/>出荷済</row>
          <row style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><div style={{width: 15, height: 15, borderRadius: 10, backgroundColor: '#FF3547', marginRight: 5}}/>未出荷あり</row>
        </div>,
        field: '処理',
        sort: 'asc',
        width: 200,
        minimal: 'sm'
      })
    }

    if (urigawa) {
      data.columns.push({
        label: <div style={{width: 100, display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
          <row style={{display: 'flex', justifyContent: 'center'}}>処理</row>
          <row style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><div style={{width: 15, height: 15, borderRadius: 10, backgroundColor: '#19A22B', marginRight: 5}}/>出荷済</row>
          <row style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><div style={{width: 15, height: 15, borderRadius: 10, backgroundColor: '#FF3547', marginRight: 5}}/>未出荷あり</row>
        </div>,
        field: '処理',
        sort: 'asc',
        width: 200,
        minimal: 'sm'
      })
    }

    let selectOption = <>
      <option value={null}>すべて</option>
    </>;

    if (urigawa) {
      selectOption = <>
        <option value={null}>すべて</option>
        <option value={4}>最新更新順</option>
        <option value={3}>受注済</option>
        <option value={2}>受注確認済</option>
        <option value={5}>未出荷あり</option>
        <option value={6}>出荷済</option>
      </>
    } else if (kaigawa) {
      selectOption = <>
        <option value={null}>すべて</option>
        <option value={4}>最新更新順</option>
        <option value={1}>上長承認待ち</option>
        <option value={2}>発注済</option>
        <option value={3}>却下</option>
        <option value={5}>未出荷あり</option>
        <option value={6}>出荷済</option>
      </>
    }


    return (
      <>
      <h2>{urigawa ? '注文書一覧' : '発注書一覧'}</h2>
        <MDBRow>
          <MDBCol>
            <MDBBtn size={'sm'} color="default" style={{padding: 10, height: 38, width: 200,margin:0}}  disabled={this.state.loadingPDF || this.state.selectedOrderList.length === 0} onClick={() => this.selectedDownloadPDF()}>
              {this.state.loadingPDF && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{marginRight: 10}}/>}
              {!this.state.loadingPDF && <MDBIcon icon="download" style={{marginRight: 10}} />}
              選択したPDF一括ダウンロード
            </MDBBtn>
            {/*<MDBBtn className="btn-sm btn-block" style={{width: 200}} disabled={this.state.loadingPDF || this.state.selectedOrderList.length === 0} onClick={() => this.selectedDownloadPDF()}>*/}
            {/*  {this.state.loadingPDF && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{marginRight: 10}}/>}*/}
            {/*  <span>PDF ダウンロード</span>*/}
            {/*</MDBBtn>*/}
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size={5} style={{display: 'flex', justifyContent: 'space-between'}}>
            <MDBBtn color="default" style={{padding: 10}} flat={true} outline={true} disabled={this.state.selectedOrderList.length === 0} onClick={() => this.orderRead()}>
              <MDBIcon icon="envelope-open" className="mr-1" /> 既読にする
            </MDBBtn>

            {/*<MDBBtn color="default" style={{padding: 10}} flat={true} outline={true} disabled={this.state.selectedOrderList.length === 0} onClick={() => this.orderRead()}>*/}
            {/*  <MDBIcon icon="file-pdf" className="mr-1" /> PDF ダウンロード*/}
            {/*</MDBBtn>*/}

            <div className="input-group md-form form-sm form-1 pl-0" style={{width: 200}}>
              <div className="input-group-prepend">
                <span className="input-group-text lighten-3" id="basic-text1">
                  <MDBIcon className="text-white" icon="search" />
                </span>
              </div>
              <input
                  className="form-control my-0 py-1"
                  type="text"
                  placeholder="文字列検索"
                  aria-label="文字列検索"
                  onChange={this.handleSearchwordAChange}
                  onKeyDown={this.handleSearchwordAKeyDown}
                  value={this.state.searchwordA}
              />
            </div>
          </MDBCol>
          <MDBCol size={7} style={{display: 'flex', justifyContent: 'flex-end'}}>

            <div className="form-check form-check-inline">
              <input type="radio" className="form-check-input" id="materialInline1" name="inlineMaterialRadiosExample" checked={this.state.sortType === 'asc'} onClick={() => this.sortChange('asc')}/>
              <label className="form-check-label" htmlFor="materialInline1">昇順</label>
            </div>

            <div className="form-check form-check-inline">
              <input type="radio" className="form-check-input" id="materialInline2" name="inlineMaterialRadiosExample" checked={this.state.sortType === 'desc'} onClick={() => this.sortChange('desc')}/>
              <label className="form-check-label" htmlFor="materialInline2">降順</label>
            </div>

            <div className="form-check form-check-inline" style={{marginLeft: 20}}>
              並べ替え
            </div>

            <div className="form-check form-check-inline">
              <select className="browser-default custom-select" value={this.state.orderType} onChange={(e) => this.selectOrderType(e)}>
                {selectOption}
              </select>
            </div>

            <div className={'form-check form-check-inline'}>
              <MDBBtn
                  color="default" style={{padding: 10}} flat={true} outline={true} onClick={() => this.clearFilter()}>
                リセット
              </MDBBtn>
            </div>


          </MDBCol>
        </MDBRow>
        <div id="wrapper1" style={{height: 20, overflow: 'auto'}}>
          <div id="div1" style={{height: 20}}>
          </div>
        </div>
      <MDBTable responsive btn small id={"div2"}>
        <MDBTableHead color="primary-color" textWhite columns={data.columns} />
        <MDBTableBody rows={data.rows} />
      </MDBTable>
      {this.state.loading && <div className="d-flex justify-content-center mt-5"><MDBSpinner /></div>}

      <MDBModal isOpen={this.state.applyModal} toggle={this.applyClose}>
        <MDBModalHeader toggle={this.applyClose}
                className="text-center default-color white-text"
                titleClass="w-100 font-weight-bold"
        >承認(発注確定)</MDBModalHeader>
        <MDBModalBody>
        {this.state.applyTitle} の発注を承認しますか？<br />
        承認すると発注が確定し、販売会社に通知されます。<br />
        よろしいですか？
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn onClick={this.applyClose} color="secondary">キャンセル</MDBBtn>
          <MDBBtn onClick={this.applyClose} color="default" data-id={this.state.applyId}>承認(発注確定)する</MDBBtn>
        </MDBModalFooter>
      </MDBModal>

        <MDBModal isOpen={this.state.rejectModal} toggle={this.rejectClose}>
          <MDBModalHeader toggle={this.rejectClose}
                  className="text-center warning-color-dark white-text"
                  titleClass="w-100 font-weight-bold"
          >却下（担当者に差戻）</MDBModalHeader>
          <MDBModalBody>
          {this.state.rejectTitle} の発注を却下（担当者に差戻）しますか？<br />
          本発注書での注文はできなくなります。<br />
          よろしいですか？
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.rejectClose} color="secondary">キャンセル</MDBBtn>
            <MDBBtn onClick={this.rejectClose} color="danger" data-id={this.state.rejectId}>却下（担当者に差戻）する</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.waitModal} toggle={this.waitClose}>
          <MDBModalHeader toggle={this.waitClose}>承認依頼</MDBModalHeader>
          <MDBModalBody>
            {this.state.waitTitle} の発注書から発注するために、上長に承認依頼をします。<br />
            よろしいですか？
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.waitClose} color="secondary">キャンセル</MDBBtn>
            <MDBBtn onClick={this.waitClose} color="danger" data-id={this.state.waitId} >上長に承認依頼する</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

      </>
    )

  }
}


export default OrderTableList;
